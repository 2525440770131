export const fetchReel = (walletAddress, tokenId) => {
  return fetch("/gallery/fetch_reel", {
    method: "POST",
    headers: {
      "X-CSRF-Token": document.head
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      wallet_address: walletAddress,
      token_id: tokenId,
    }),
  }).then((response) => response.json());
};
