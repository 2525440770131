export const updateAllTargets = (namespace, name, action) => {
  document.querySelectorAll(`[data-${namespace}-target]`).forEach((el) => {
    if (el.dataset[`${parameterize(namespace)}Target`] === name) action(el);
  });
};

export const showAllTargets = (namespace, name) => {
  document.querySelectorAll(`[data-${namespace}-target]`).forEach((el) => {
    if (el.dataset[`${parameterize(namespace)}Target`] === name)
      el.classList.remove("hidden");
  });
};

export const hideAllTargets = (namespace, name) => {
  document.querySelectorAll(`[data-${namespace}-target]`).forEach((el) => {
    if (el.dataset[`${parameterize(namespace)}Target`] === name)
      el.classList.add("hidden");
  });
};

const parameterize = (str) => {
  //remove non alphanumeric characters from string and convert to camel case
  return str
    .replace(/[^a-zA-Z0-9]/g, " ")
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index == 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};
