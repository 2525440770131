import { Controller } from "@hotwired/stimulus";
import {
  connectWallet,
  getAddress,
  disconnectProvider,
  checkNetwork,
} from "../utils/connect_wallet";
import { getEnsAddress } from "../utils/web3utils";
import { verifyAddress } from "../requests/verify_address";
import { logTransaction } from "../requests/log_transaction";
import { trackEvent } from "../utils/google_analytics";
import * as Sentry from "@sentry/browser";
import { ethers } from "ethers";
import { getValue, getInt } from "../utils/contract_helpers";
import { formatAddress, formatThousands } from "../utils/formatters";
import { trackHeap } from "../utils/heap";

let destinationWallet;

const DEFAULT_STRINGS = {
  CONNECT_BUTTON_CONNECT: "Connect Wallet",
  CONNECT_BUTTON_CONNECTING: "Connecting...",
  MINT_BUTTON_DEFAULT: "Mint",
  MINT_BUTTON_MINTING: "Minting...",
  MINT_BUTTON_WAITING: "Minting...",
  MINT_BUTTON_MINTED: "Minted!",
  MINT_BUTTON_INSUFFICIENT_FUNDS: "Insufficient Funds",
  MINT_BUTTON_SOLD_OUT: "Sold Out",
  MINT_BUTTON_LIMIT_REACHED: "Limit Reached",
  MINT_BUTTON_INACTIVE: "Sale Paused",
  MINT_BUTTON_NOT_ON_WHITELIST: "Not on presale list",
};

export default class extends Controller {
  static values = {
    contract: String,
    disableTracking: Boolean,
    externalContract: Boolean,
    fallbackUrl: String,
    mintFunction: String,
    onPrivateSale: Boolean,
    onPublicSale: Boolean,
    template: String,
    url: String,
    whitelistMintFunction: String,
    hideMintForClaims: Boolean,
  };

  static targets = [
    "connectButtonContainer",
    "mintButtonContainer",
    "errorContainer",
    "successContainer",
    "soldOutContainer",
    "connectButton",
    "quantitySelector",
    "mintButton",
    "priceDisplay",
    "countDisplay",
    "maxPerWalletDisplay",
    "errorMessage",
    "successMessage",
    "onSaleBanner",
    "soldOutBanner",
    "claimButton",
    "claimContainer",
    "claimButtonContainer",
    "claimSuccessContainer",
    "claimTitle",
    "connectWalletButton",
    "walletIcon",
    "walletText",
    "walletDisconnect",
    "presaleContainer",
  ];

  async connect() {
    let { ERC721ABI, ERC721ByteCode } = await import(
      `../contracts/${this.templateValue}/contract`
    );

    try {
      const { STRINGS } = await import(`../strings/${this.templateValue}.js`);

      this.strings = STRINGS;

      console.log("Custom strings loaded");
    } catch (e) {
      console.log("Default strings loaded");
      this.strings = DEFAULT_STRINGS;
    }

    this.contractABI = JSON.parse(ERC721ABI);
    this.contractByteCode = ERC721ByteCode;

    this.mintStatus = {};
    this.enableTracking = window.masonConfig.enableTracking;
  }

  async connectWallet() {
    if (this.connectingWallet) return;

    if (this.provider && this.destinationWallet) {
      await disconnectProvider(this.provider);

      // Clear internal variables
      this.provider = null;
      this.destinationWallet = null;

      // Update the UI to reflect disconnected state
      this.renderDisconnected();
    } else {
      this.renderConnecting();

      try {
        let provider = await connectWallet(
          checkNetwork,
          this.connectWallet.bind(this),
          this.onDisconnect.bind(this)
        );
        this.provider = provider;

        destinationWallet = await getAddress(this.provider);
        this.destinationWallet = destinationWallet;
        this.ensAddress = await getEnsAddress(this.provider);

        this.ineligible = false;
      } catch (e) {
        // Something went wrong and we were unable to connect wallet. Switch
        // to disconnected state.
        Sentry.captureException(e);
        this.renderDisconnected();
      }

      // provider.on('chainchanged', );
      // provider.on('accountschanged', );
      // provider.on("disconnect", () => { });

      let networkDetails = await this.provider.getNetwork();
      checkNetwork(networkDetails.chainId);

      if (this.externalContractValue) {
        // TODO: Can we query Mason for status with extenal contract?
        this.contract = new ethers.Contract(
          this.contractValue,
          this.contractABI,
          this.provider
        );
        await this.populateDetails();
      } else {
        await this.getMintStatus();
      }

      trackHeap("Connect Wallet", {});

      this.renderConnected();
    }
  }

  async onDisconnect() {
    this.provider = null;
    this.destinationWallet = null;
    this.renderDisconnected();
  }

  connectMouseOver() {
    if (!this.provider) return false;

    if (this.hasWalletIconTarget) this.walletIconTarget.classList.add("hidden");
    if (this.hasWalletTextTarget) this.walletTextTarget.classList.add("hidden");
    if (this.hasWalletDisconnectTarget)
      this.walletDisconnectTarget.classList.remove("hidden");
  }

  connectMouseOut() {
    if (!this.provider) return false;

    if (this.hasWalletIconTarget)
      this.walletIconTarget.classList.remove("hidden");
    if (this.hasWalletTextTarget)
      this.walletTextTarget.classList.remove("hidden");
    if (this.hasWalletDisconnectTarget)
      this.walletDisconnectTarget.classList.add("hidden");
  }

  renderConnected() {
    this.connectingWallet = false;

    if (this.hasWalletTextTarget)
      this.walletTextTarget.innerText =
        this.ensAddress || formatAddress(this.destinationWallet);
    if (this.hasWalletIconTarget)
      this.walletIconTarget.classList.remove("hidden");

    this.hideConnectContainer();
    this.showMintContainer();
  }

  renderConnecting() {
    this.connectingWallet = true;
    this.setConnectButtonLabel(this.strings["CONNECT_BUTTON_CONNECTING"]);
    if (this.hasWalletTextTarget)
      this.walletTextTarget.innerText =
        this.strings["CONNECT_BUTTON_CONNECTING"];
  }

  renderDisconnected() {
    this.connectingWallet = false;

    if (this.hasWalletTextTarget)
      this.walletTextTarget.innerText = this.strings["CONNECT_BUTTON_CONNECT"];
    if (this.hasWalletTextTarget)
      this.walletTextTarget.classList.remove("hidden");
    if (this.hasWalletIconTarget) this.walletIconTarget.classList.add("hidden");
    if (this.hasWalletDisconnectTarget)
      this.walletDisconnectTarget.classList.add("hidden");

    this.setConnectButtonLabel(this.strings["CONNECT_BUTTON_CONNECT"]);
    this.hideMintContainer();
    this.showConnectContainer();
  }

  showSoldOutContainer() {
    if (this.hasCountDisplayTarget) this.countDisplayTarget.innerText = "";
    if (this.hasPriceDisplayTarget)
      this.priceDisplayTarget.innerHTML = "Sold Out";
    this.soldOutContainerTarget.classList.remove("hidden");
  }

  showMintContainer() {
    if (
      this.hasMintButtonContainerTarget &&
      !(this.claimSignature && this.hideMintForClaimsValue)
    )
      this.mintButtonContainerTarget.classList.remove("hidden");
  }

  showClaimContainer() {
    if (this.hasClaimContainerTarget)
      this.claimContainerTarget.classList.remove("hidden");

    if (this.hideMintForClaimsValue) this.hideMintContainer();
  }

  hideMintContainer() {
    if (this.hasMintButtonContainerTarget)
      this.mintButtonContainerTarget.classList.add("hidden");
  }

  showConnectContainer() {
    if (this.hasConnectButtonContainerTarget)
      this.connectButtonContainerTarget.classList.remove("hidden");
  }

  hideConnectContainer() {
    if (this.hasConnectButtonContainerTarget)
      this.connectButtonContainerTarget.classList.add("hidden");
  }

  setMintButtonLabel(s) {
    if (this.hasMintButtonTarget) {
      this.mintButtonTarget.getElementsByTagName("span")[0].innerText = s;
    }
  }

  setClaimButtonLabel(s) {
    if (this.hasClaimButtonTarget) {
      this.claimButtonTarget.getElementsByTagName("span")[0].innerText = s;
    }
  }

  setConnectButtonLabel(s) {
    if (this.hasConnectButtonTarget) {
      this.connectButtonTarget.innerText = s;
    }
  }

  async getMintStatus() {
    let response = await verifyAddress(
      this.destinationWallet,
      this.contractValue
    );

    this.mintStatus = response;

    const {
      contractAddress,
      pendingClaim,
      claimQuantity,
      claimSignature,
      signature,
    } = this.mintStatus;

    if (contractAddress) {
      this.contract = new ethers.Contract(
        contractAddress,
        this.contractABI,
        this.provider
      );
      window.contract = this.contract;
      await this.populateDetails();
    }

    if (pendingClaim) {
      this.claimQuantity = claimQuantity;
      this.claimSignature = claimSignature;
      if (this.hasClaimTitleTarget) {
        if (this.claimQuantity > 1) {
          this.claimTitleTarget.innerText = `Claim your ${this.claimQuantity} free Gen 3 Tokens`;
        } else {
          this.claimTitleTarget.innerText = "Claim your free Gen 3 Token";
        }
      }
      await this.checkClaim();
    }
  }

  async checkClaim() {
    let response = await this.contract.hasUnclaimedTokens(
      this.destinationWallet
    );

    if (response) {
      this.showClaimContainer();
    }
  }

  async getRemainingMints() {
    let response;
    try {
      response = await this.contract.allowedMintCount(this.destinationWallet);
    } catch (e) {
      return 1;
    }
    return response;
  }

  enableMintButton() {
    if (this.hasQuantitySelectorTarget) {
      this.quantitySelectorTarget.disabled = false;
      this.quantitySelectorTarget.classList.add("dropdown");
      this.quantitySelectorTarget.classList.remove("dropdown-disabled");
    }

    this.mintButtonTarget.disabled = false;
    this.mintButtonTarget.classList.add("button");
    this.mintButtonTarget.classList.remove("button-disabled");
  }

  disableMintButton() {
    if (this.hasQuantitySelectorTarget) {
      this.quantitySelectorTarget.disabled = true;
      this.quantitySelectorTarget.classList.add("dropdown-disabled");
      this.quantitySelectorTarget.classList.remove("dropdown");
    }

    //this.mintButtonTarget.disabled = true;
    this.mintButtonTarget.classList.add("button-disabled");
    this.mintButtonTarget.classList.remove("button");
  }

  enableClaimButton() {
    this.claimButtonTarget.disabled = false;
    this.claimButtonTarget.classList.add("button");
    this.claimButtonTarget.classList.remove("button-disabled");
  }

  disableClaimButton() {
    this.claimButtonTarget.disabled = true;
    this.claimButtonTarget.classList.add("button-disabled");
    this.claimButtonTarget.classList.remove("button");
  }

  onPresale() {
    return this.whitelistIsActive && !this.saleIsActive;
  }

  async checkWhitelistStatus() {
    let response;
    if (!this.destinationWallet || !this.mintStatus.signature) {
      response = false;
    } else {
      try {
        response = await this.contract
          .connect(this.provider.getSigner())
          .checkWhitelist(this.mintStatus.signature);
      } catch (e) {
        response = false;
      }
    }
    return response;
  }

  canMint() {
    return (
      ((this.onPresale() && this.isWhitelisted) || this.saleIsActive) &&
      this.remainingMints > 0
    );
  }

  currentPrice() {
    if (this.onPresale()) {
      return this.whitelistPrice;
    } else {
      return this.price;
    }
  }

  updateCount() {
    if (this.hasCountDisplayTarget) {
      this.countDisplayTarget.innerText = `${formatThousands(
        this.remainingSupply
      )} available`;
    }
  }

  updateMaxPerWallet() {
    if (this.hasMaxPerWalletDisplayTarget) {
      this.maxPerWalletDisplayTarget.innerText = `Max ${this.maxPerWallet} per wallet`;
    }
  }

  updateQuantitySelector() {
    if (!this.hasQuantitySelectorTarget) return true;

    let maxQuantity = Math.min(
      this.currentMaxMint(),
      this.remainingSupply,
      this.remainingMints
    );

    // This contract does not implement max mint, need to think of a more generic
    // way to handle this.
    if (this.templateValue === "daohouse-friends") {
      maxQuantity = Math.min(this.remainingSupply, this.remainingMints);
    }

    let maxValue = 0;
    for (let i = 0; i < this.quantitySelectorTarget.options.length; i++) {
      let value = parseInt(this.quantitySelectorTarget.options[i].value);
      if (value > maxValue) {
        maxValue = value;
      }
    }

    if (maxValue !== maxQuantity) {
      this.quantitySelectorTarget.innerHTML = "";
      let elements = "";
      for (var i = 0; i < maxQuantity; i++) {
        var opt = i + 1;
        elements += '<option value="' + opt + '">' + opt + "</option>";
      }
      this.quantitySelectorTarget.innerHTML = elements;
    }
  }

  currentMaxMint() {
    if (this.onPresale()) {
      return this.maxWhitelistMint;
    } else {
      return this.maxMint;
    }
  }

  async populateDetails() {
    console.log("Fetching mint details...");

    this.totalSupply = await getInt(
      this.contract,
      "totalPublicSupply",
      getInt(this.contract, "totalSupply", 0)
    ); // Note, this is PUBLIC supply
    this.maxSupply = await getInt(this.contract, "MAX_SUPPLY", 1000000);
    this.reservedSupply = await getInt(this.contract, "MAX_RESERVED_SUPPLY", 0);
    this.remainingMints = await this.getRemainingMints();
    this.maxMint = await getValue(
      this.contract,
      "MAX_MULTIMINT",
      this.remainingMints
    );

    this.maxPerWallet = await getValue(this.contract, "MAX_PER_WALLET", 10000);
    this.saleIsActive = await getValue(this.contract, "saleIsActive", false);
    this.whitelistIsActive = await getValue(
      this.contract,
      "whitelistIsActive",
      false
    );
    this.remainingSupply =
      this.maxSupply - this.reservedSupply - this.totalSupply;

    this.price = await getValue(this.contract, "PRICE", 0);

    this.maxWhitelistMint = await getValue(
      this.contract,
      "MAX_WHITELIST_MULTIMINT",
      this.remainingMints
    );

    this.whitelistPrice = await getValue(this.contract, "WHITELIST_PRICE", 0);

    if (this.onPresale()) {
      this.isWhitelisted = await this.checkWhitelistStatus();
      this.togglePresaleContainer();
    }

    this.updatePrice();
    this.updateCount();
    this.updateMaxPerWallet();
    this.updateQuantitySelector();

    this.setMintButtonLabel(this.mintButtonLabel());

    if (this.remainingSupply <= 0) {
      this.hideMintContainer();
      this.showSoldOutContainer();
    } else {
      if (this.canMint()) {
        this.enableMintButton();
      } else {
        this.disableMintButton();
      }
    }

    // run this method in 30 seconds
    setTimeout(this.populateDetails.bind(this), 60000);

    console.log("Loaded Mint Details..");
  }

  togglePresaleContainer() {
    if (!this.hasPresaleContainerTarget) return;

    if (this.isWhitelisted) {
      this.presaleContainerTarget.classList.add("hidden");
    } else {
      this.presaleContainerTarget.classList.remove("hidden");
    }
  }

  mintMethod() {
    if (this.onPresale()) {
      return this.whitelistMintFunctionValue || "whitelistMint";
    } else {
      return this.mintFunctionValue || "mint";
    }
  }

  mintButtonLabel() {
    if (this.onPresale() && !this.isWhitelisted) {
      this.ineligible = true;
      return this.strings["MINT_BUTTON_NOT_ON_WHITELIST"];
    } else {
      if (this.remainingSupply <= 0) {
        return this.strings["MINT_BUTTON_SOLD_OUT"];
      } else {
        if (this.saleIsActive || this.whitelistIsActive) {
          if (this.remainingMints <= 0) {
            return this.strings["MINT_BUTTON_LIMIT_REACHED"];
          } else {
            return this.strings["MINT_BUTTON_DEFAULT"];
          }
        } else {
          return this.strings["MINT_BUTTON_INACTIVE"];
        }
      }
    }
  }

  updatePrice() {
    let quantity = this.hasQuantitySelectorTarget
      ? this.quantitySelectorTarget.value
      : 1;
    let value = this.currentPrice() * quantity;

    if (this.hasPriceDisplayTarget) {
      this.priceDisplayTarget.innerText = `${ethers.utils.formatEther(
        `${value}`
      )} ETH`;
    }
  }

  showError(s) {
    this.errorMessageTarget.innerText = s;
    this.errorContainerTarget.classList.remove("hidden");
  }

  hideError() {
    this.errorContainerTarget.classList.add("hidden");
  }

  showSuccess() {
    this.successContainerTarget.classList.remove("hidden");
  }

  hideSuccess() {
    this.successContainerTarget.classList.add("hidden");
  }

  hideClaimButtonContainer() {
    if (this.hasClaimButtonContainerTarget)
      this.claimButtonContainerTarget.classList.add("hidden");
  }

  showClaimSuccessContainer() {
    if (this.hasClaimSuccessContainerTarget)
      this.claimSuccessContainerTarget.classList.remove("hidden");
  }

  async claim() {
    if (this.claimButtonTarget.classList.contains("button-disabled")) {
      return false;
    }

    this.setClaimLoadingState();

    const contract = new ethers.Contract(
      this.contractValue,
      this.contractABI,
      this.provider
    );

    try {
      let tx = await contract
        .connect(this.provider.getSigner())
        .claimTokens(this.claimQuantity, this.claimSignature, { value: 0 });

      this.setClaimButtonLabel(this.strings["MINT_BUTTON_WAITING"]);
      await tx.wait();

      this.setClaimButtonLabel(this.strings["MINT_BUTTON_MINTED"]);
      this.hideClaimButtonContainer();
      this.showClaimSuccessContainer();

      trackHeap("Claim", { quantity: this.claimQuantity });
    } catch (e) {
      if (
        e["message"].indexOf("You have already claimed all your tokens") >= 0
      ) {
        this.setClaimButtonLabel("Already Claimed");
      } else {
        Sentry.captureException(e);
        this.setClaimButtonLabel("Claim");
        this.cancelClaimLoadingState();
      }
    }
  }

  async mint() {
    if (
      !this.canMint() ||
      this.mintButtonTarget.classList.contains("button-disabled")
    ) {
      window.mason = this;
      if (this.ineligible && this.fallbackUrlValue) {
        window.location.href = this.fallbackUrlValue;
      }

      return false;
    }

    this.hideError();
    this.hideSuccess();

    if (typeof this.contract === "undefined") {
      this.connectWallet();
    } else {
      this.setLoadingState();
      this.setMintButtonLabel(this.strings["MINT_BUTTON_MINTING"]);

      let quantity = this.hasQuantitySelectorTarget
        ? this.quantitySelectorTarget.value
        : 1;
      if (isNaN(quantity)) quantity = 1;

      let value = this.currentPrice() * quantity;

      const contract = new ethers.Contract(
        this.contractValue,
        this.contractABI,
        this.provider
      );

      let tx;
      try {
        if (this.externalContractValue && this.urlValue) {
          // TODO: Make this more useful
          tx = await contract
            .connect(this.provider.getSigner())
            .mint(this.urlValue, { value: String(value) });
        } else {
          // TODO: Dynamically call mint functions
          if (this.onPresale()) {
            tx = await contract
              .connect(this.provider.getSigner())
              .whitelistMint(quantity, this.mintStatus.signature, {
                value: String(value),
              });
          } else {
            tx = await contract
              .connect(this.provider.getSigner())
              .mint(quantity, { value: String(value) });
          }
        }

        let transactionHash = tx["hash"] || tx["transactionHash"];
        try {
          if (!this.disableTrackingValue) {
            logTransaction(
              transactionHash,
              this.destinationWallet,
              quantity,
              String(value),
              this.contractValue
            );
          }
        } catch (e) {
          console.log(e);
        }

        if (!this.disableTrackingValue) {
          trackEvent("mint", this.destinationWallet, transactionHash, quantity);
        }

        this.setMintButtonLabel(this.strings["MINT_BUTTON_WAITING"]);
        await tx.wait();

        this.populateDetails();
        this.setMintButtonLabel(this.strings["MINT_BUTTON_MINTED"]);
        this.showSuccess();
        this.cancelLoadingState();

        trackHeap("Mint", { quantity: quantity, amount: String(this.value) });
      } catch (e) {
        if (e["message"].indexOf("User denied transaction signature") >= 0) {
          this.setMintButtonLabel(this.strings["MINT_BUTTON_DEFAULT"]);
          this.populateDetails();
          this.cancelLoadingState();
        } else if (e["message"].indexOf("insufficient funds") >= 0) {
          this.setMintButtonLabel(
            this.strings["MINT_BUTTON_INSUFFICIENT_FUNDS"]
          );
          this.cancelLoadingState();
        } else if (
          e["message"].indexOf("ERROR_EXCEEDS_MAX_PER_WALLET") >= 0 ||
          e["message"].indexOf("Minting limit exceeded") >= 0
        ) {
          this.setMintButtonLabel(this.strings["MINT_BUTTON_LIMIT_REACHED"]);
          this.populateDetails();
          this.cancelLoadingState();
          this.showError(
            "You have minted the maximum amount of tokens allowed per wallet"
          );
        } else if (
          e["message"].indexOf("ERROR_EXCEEDS_MAX_SUPPLY") >= 0 ||
          e["message"].indexOf("Exceeds max supply") >= 0
        ) {
          this.setMintButtonLabel(this.strings["MINT_BUTTON_SOLD_OUT"]);
          this.populateDetails();
          this.cancelLoadingState();
        } else {
          Sentry.captureException(e);
          console.log(e);
          this.setMintButtonLabel(this.strings["MINT_BUTTON_DEFAULT"]);
          this.cancelLoadingState();
          this.showError("Transaction Failed - Please Try Again");
          return false;
        }
      }
    }
  }

  setLoadingState() {
    this.disableMintButton();
  }

  cancelLoadingState() {
    this.enableMintButton();
  }

  setClaimLoadingState() {
    this.disableClaimButton();
  }

  cancelClaimLoadingState() {
    this.enableClaimButton();
  }
}
