import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tokenId"];

  connect() {}

  verify() {
    const tokenId = parseInt(this.tokenIdTarget.value);

    // If token ID s not a number, return
    if (isNaN(tokenId) || tokenId <= 0 || tokenId > 6884) {
      alert("Token ID must be a number between 1 and 6884");
      this.tokenIdTarget.value = "";
      return;
    } else {
      // redirect to /verify/:token_id
      window.location.href = `/verify/${tokenId}`;
    }
  }
}
