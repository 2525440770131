import { Controller } from "@hotwired/stimulus";
import { verifyAddress } from "../requests/verify_address";
import { logTransaction } from "../requests/log_transaction";
import { trackEventWithContract } from "../utils/google_analytics";
import * as Sentry from "@sentry/browser";
import { ethers } from "ethers";
import { getValue, getInt } from "../utils/contract_helpers";
import JSConfetti from "js-confetti";

const CONNECT_BUTTON_CONNECT = "Connect Wallet";
const CONNECT_BUTTON_CONNECTING = "Connecting...";
const SALE_NOT_STARTED = "Sale Not Started";
const MINT_BUTTON_DEFAULT = "Mint";
const MINT_BUTTON_MINTING = "Minting...";
const MINT_BUTTON_WAITING = "Processing...";
const MINT_BUTTON_MINTED = "Success!";
const MINT_BUTTON_INSUFFICIENT_FUNDS = "Insufficient Funds";
const MINT_BUTTON_SOLD_OUT = "Sold Out";
const MINT_BUTTON_LIMIT_REACHED = "Minting Limit Reached";
const MINT_BUTTON_NOT_ON_WHITELIST = "Not on the list";

export default class extends Controller {
  static values = {
    audio: String,
    confettiEmoji: String,
    contract: String,
    contractAddress: String,
    price: String,
    quantityThreshold: Number,
    restrictedQuantity: Boolean,
    showCrossmint: Boolean,
    simpleWhiteList: Boolean,
  };

  static targets = [
    "connectText",
    "mintContainer",
    "connectContainer",
    "errorContainer",
    "successContainer",
    "quantitySelector",
    "mintPrice",
    "mintButton",
    "connectWalletButton",
    "crossmintContainer",
    "errorMessage",
    "supplyDisplay",
  ];

  async connect() {
    console.log("[MintButton] Connecting");

    if (this.contractValue) {
      let { ERC721ABI, ERC721ByteCode } = await import(
        `../contracts/${this.contractValue}/contract`
      );
      this.contractABI = JSON.parse(ERC721ABI);
      this.contractByteCode = ERC721ByteCode;
    }

    if (this.priceValue) {
      this.price = ethers.utils.parseUnits(this.priceValue);
    }

    if (!this.showCrossmintValue) {
      if (this.hasCrossmintContainerTarget)
        this.crossmintContainerTarget.classList.add("hidden");
    }

    this.mintDisabled = true;
    this.quantity = 1;
  }

  async connectWallet() {
    console.log("[MintButton] Connecting wallet");
    // We ask our Wallet Connect controller to connect the wallet
    const event = new CustomEvent("mint:connect");
    window.dispatchEvent(event);
  }

  async onConnect() {
    console.log("[MintButton] Connected");

    await this.getMintStatus();
  }

  onDisconnect() {
    console.log("[MintButton] Disconnected");
    this.renderDisconnected();
  }

  renderConnected() {
    if (this.hasConnectContainerTarget)
      this.connectContainerTarget.classList.add("hidden");
    if (this.hasErrorContainerTarget)
      this.errorContainerTarget.classList.add("hidden");
    if (this.hasSuccessContainerTarget)
      this.successContainerTarget.classList.add("hidden");
    if (this.hasMintContainerTarget)
      this.mintContainerTarget.classList.remove("hidden");
  }

  renderConnecting() {
    this.setConnectButtonLabel(CONNECT_BUTTON_CONNECTING);
  }

  renderDisconnected() {
    this.setConnectButtonLabel(CONNECT_BUTTON_CONNECT);

    if (this.hasConnectContainerTarget)
      this.connectContainerTarget.classList.remove("hidden");
    if (this.hasErrorContainerTarget)
      this.errorContainerTarget.classList.add("hidden");
    if (this.hasSuccessContainerTarget)
      this.successContainerTarget.classList.add("hidden");
    if (this.hasMintContainerTarget)
      this.mintContainerTarget.classList.add("hidden");
  }

  setConnectButtonLabel(s) {
    if (this.hasConnectTextTarget) {
      this.connectTextTarget.innerText = s;
    }
  }

  async getMintStatus() {
    this.renderConnecting();

    if (!this.contractAddressValue) return false;

    let response = await verifyAddress(
      window.destinationWallet,
      this.contractAddressValue
    );

    this.mintStatus = response;

    const {
      onPublicSale,
      onPresale,
      signature,
      whitelisted,
      price,
      saleStartsAt,
      maxMint,
    } = response;

    if (!onPublicSale && !onPresale) {
      this.setConnectButtonLabel(SALE_NOT_STARTED);
      setTimeout(this.getMintStatus.bind(this), 60 * 1000);
    } else {
      this.signature = signature;
      if (this.signature && price && !this.restrictedQuantityValue)
        this.discountedPrice = price;
      if (this.signature && maxMint && this.restrictedQuantityValue)
        this.maxMint = maxMint;

      this.contract = new ethers.Contract(
        this.contractAddressValue,
        this.contractABI,
        window.provider
      );

      await this.populateDetails();

      this.renderConnected();
    }
  }

  _renderConfetti() {
    if (this.confettiEmojiValue) {
      const jsConfetti = new JSConfetti();
      jsConfetti.addConfetti({
        emojis: [...this.confettiEmojiValue],
        confettiRadius: 100,
        confettiNumber: 50,
      });
    }
  }

  _playAudio() {
    if (this.audioValue) {
      document.body.audio.src = this.audioValue;
      document.body.audio.play();
    }
  }

  async mint() {
    if (this.mintDisabled) return;

    this.hideError();
    this.hideSuccess();

    if (typeof this.contract === "undefined") {
      this.connectWallet();
    } else {
      this.setLoadingState();
      this.setMintButtonLabel(MINT_BUTTON_MINTING);

      let quantity = this.quantity || 1;
      let price = this.discountedPrice || this.price;
      let value = this.calculateMintPrice();

      let tx;

      try {
        if (this.signature) {
          const discountValue = this.restrictedQuantityValue
            ? this.maxMint
            : price;

          if (this.simpleWhiteListValue) {
            tx = await this.contract
              .connect(window.provider.getSigner())
              .whitelistMint(quantity, this.signature, {
                value: String(value),
              });
          } else {
            tx = await this.contract
              .connect(window.provider.getSigner())
              .whitelistMint(quantity, `${discountValue}`, this.signature, {
                value: String(value),
              });
          }
          console.log(tx);
        } else {
          tx = await this.contract
            .connect(window.provider.getSigner())
            .mint(quantity, {
              value: String(value),
            });
        }

        this._renderConfetti();
        this._playAudio();

        let transactionHash = tx["hash"] || tx["transactionHash"];
        try {
          logTransaction(
            transactionHash,
            window.destinationWallet,
            quantity,
            String(value),
            this.contractAddress
          );
        } catch (e) {
          console.log("Error Logging TX", e);
        }

        trackEventWithContract(
          "mint",
          this.contractAddressValue,
          window.destinationWallet,
          transactionHash,
          quantity
        );

        this.setMintButtonLabel(MINT_BUTTON_WAITING);
        await tx.wait();

        const receipt = await window.provider.getTransactionReceipt(
          transactionHash
        );

        this.transactionHash = transactionHash;

        if (receipt.status === 1) {
          this.mintedTokenId = parseInt(receipt.logs[0].topics[3], 16);
        }

        this.setMintButtonLabel(MINT_BUTTON_MINTED);
        this.showSuccess();
        this.populateDetails();
        this.cancelLoadingState();
      } catch (e) {
        Sentry.captureException(e);

        if (e["message"].indexOf("User denied transaction signature") >= 0) {
          this.setMintButtonLabel(MINT_BUTTON_DEFAULT);
          this.populateDetails();
          this.cancelLoadingState();
        } else if (e["message"].indexOf("insufficient funds") >= 0) {
          this.setMintButtonLabel(MINT_BUTTON_INSUFFICIENT_FUNDS);
          this.cancelLoadingState();
        } else if (
          e["message"].indexOf("ERROR_EXCEEDS_MAX_PER_WALLET") >= 0 ||
          e["message"].indexOf("Minting limit exceeded") >= 0
        ) {
          this.setMintButtonLabel(MINT_BUTTON_LIMIT_REACHED);
          this.populateDetails();
          this.cancelLoadingState();
          this.showError(
            "You have minted the maximum amount of tokens allowed per wallet"
          );
        } else if (
          e["message"].indexOf("ERROR_EXCEEDS_MAX_SUPPLY") >= 0 ||
          e["message"].indexOf("Exceeds max supply") >= 0
        ) {
          this.setMintButtonLabel(MINT_BUTTON_SOLD_OUT);
          this.populateDetails();
          this.cancelLoadingState();
        } else {
          Sentry.captureException(e);
          this.setMintButtonLabel(MINT_BUTTON_DEFAULT);
          this.cancelLoadingState();
          this.showError("Transaction Failed - Please Try Again");
          return false;
        }
      }
    }
  }

  setLoadingState() {
    this.disableMintButton();
  }

  cancelLoadingState() {
    this.enableMintButton();
  }

  showError(e) {
    this.errorMessageTarget.innerText = e;
    if (this.hasErrorContainerTarget)
      this.errorContainerTarget.classList.remove("hidden");

    const event = new CustomEvent("mint:error", {
      detail: {},
    });
    window.dispatchEvent(event);
  }

  showSuccess() {
    if (this.hasSuccessContainerTarget)
      this.successContainerTarget.classList.add("hidden");

    const event = new CustomEvent("mint:success", {
      detail: {
        address: window.destinationWallet,
        contract: this.contractAddressValue,
        tokenId: this.mintedTokenId || 1,
        txHash: this.transactionHash,
      },
    });
    window.dispatchEvent(event);
  }

  hideError() {
    if (this.hasErrorContainerTarget)
      this.errorContainerTarget.classList.add("hidden");

    const event = new CustomEvent("mint:error:hide");
    window.dispatchEvent(event);
  }

  hideSuccess() {
    if (this.hasSuccessContainerTarget)
      this.successContainerTarget.classList.add("hidden");

    const event = new CustomEvent("mint:success:hide");
    window.dispatchEvent(event);
  }

  async populateDetails() {
    console.log("[MintButton] populateDetails");

    this.whitelistIsActive = await getValue(
      this.contract,
      "whitelistIsActive",
      false
    );
    this.saleIsActive = await getValue(this.contract, "saleIsActive", false);
    this.crossmintIsActive = await getValue(
      this.contract,
      "crossmintIsActive",
      false
    );

    if (!this.crossmintIsActive || this.signature) {
      if (this.hasCrossmintContainerTarget)
        this.crossmintContainerTarget.classList.add("hidden");
    }

    this.maxSupply = await getInt(this.contract, "MAX_SUPPLY");
    this.totalSupply = await getInt(this.contract, "totalSupply");
    this.remainingSupply = this.maxSupply - this.totalSupply;
    this.allowedMintCount = await this.getRemainingMints();

    this.price = await this._fetchPrice();

    this.renderMintPrice();
    this._updateQuantitySelector();
    this._updateSupply();

    if (!this.saleIsActive && !this.whitelistIsActive) {
      this.setMintButtonLabel(SALE_NOT_STARTED);
      this.disableMintButton();
    }

    let whitelistEligible = await this._whitelistEligible();

    if (!this.saleIsActive && !whitelistEligible) {
      this.setMintButtonLabel(MINT_BUTTON_NOT_ON_WHITELIST);
      this.disableMintButton();
    }

    if (this.saleIsActive || whitelistEligible) {
      if (
        (typeof this.maxSupply === "undefined" || this.remainingSupply > 0) &&
        this.allowedMintCount > 0
      ) {
        this.enableMintButton();
        this.renderMint();
      } else {
        if (this.remainingSupply > 0 && this.allowedMintCount <= 0) {
          this.setMintButtonLabel(MINT_BUTTON_LIMIT_REACHED);
        } else {
          this.setMintButtonLabel(MINT_BUTTON_SOLD_OUT);
        }
        this.disableMintButton();
      }
    }

    setTimeout(this.populateDetails.bind(this), 60000);
  }

  _updateSupply() {
    // Target doesn't register due to TurboFrame, we need to do this the old fashioned way
    const supplyEl = this.element.getElementsByClassName("stats__count")[0];
    if (
      supplyEl &&
      this.quantityThresholdValue &&
      this.remainingSupply <= this.quantityThresholdValue
    ) {
      let text = "";
      if (!this.maxSupply) {
        text = "Unlimited Supply";
      } else {
        text = `${this.remainingSupply.toLocaleString()} available`;
      }
      supplyEl.innerText = text;
    } else {
      supplyEl.innerText = "";
    }
  }

  async _whitelistEligible() {
    if (!this.whitelistIsActive) return false;
    if (!this.signature) return false;

    let response;
    if (this.simpleWhiteListValue) {
      try {
        response = await this.contract
          .connect(window.provider.getSigner())
          .checkWhitelist(this.signature);
      } catch (e) {
        Sentry.captureException(e);
        response = false;
      }
    } else {
      const discountValue = this.restrictedQuantityValue
        ? this.maxMint
        : this.discountedPrice;

      try {
        response = await this.contract
          .connect(window.provider.getSigner())
          .checkWhitelist(`${discountValue}`, this.signature);
      } catch (e) {
        Sentry.captureException(e);
        response = false;
      }
    }

    return response;
  }

  async _fetchPrice() {
    let price = parseInt(await getValue(this.contract, "PRICE", 0), 10);
    try {
      if (this.signature && this.discountedPrice) {
        const discountValid = await this.contract
          .connect(window.provider.getSigner())
          .checkPrice(`${this.discountedPrice}`, this.signature);
        if (discountValid) {
          price = this.discountedPrice;
        }
      }
    } catch (e) {
      Sentry.captureException(e);
    }

    return price;
  }

  disableMintButton() {
    this.mintDisabled = true;
    this.quantitySelectorTarget.disabled = true;
    this.quantitySelectorTarget.classList.add("dropdown-disabled");
    this.quantitySelectorTarget.classList.remove("dropdown");

    this.mintButtonTarget.classList.add("button-disabled");
    this.mintButtonTarget.classList.remove("button");
  }

  _updateQuantitySelector() {
    let maxQuantity = this.allowedMintCount;
    let element = this.quantitySelectorTarget;
    let maxValue = 0;

    if (this.maxSupply) {
      maxQuantity = Math.min(this.remainingSupply, this.allowedMintCount);
    }

    for (let i = 0; i < element.options.length; i++) {
      let value = parseInt(element.options[i].value);
      if (value > maxValue) {
        maxValue = value;
      }
    }

    if (maxValue !== maxQuantity) {
      element.innerHTML = "";
      let elements = "";
      for (var i = 0; i < maxQuantity; i++) {
        var opt = i + 1;
        elements += '<option value="' + opt + '">' + opt + "</option>";
      }

      element.innerHTML = elements;
    }
  }

  updatePrice() {
    this.quantity = this.quantitySelectorTarget.value;
    this.renderMintPrice();

    if (this.showCrossmintValue) {
      let crossmintButton = document.getElementById(
        `cm__${this.contractAddressValue}`
      );
      crossmintButton.setAttribute(
        "mintconfig",
        JSON.stringify({
          type: "erc-721",
          _count: this.quantity,
          totalPrice: ethers.utils.formatEther(`${this.calculateMintPrice()}`),
        })
      );
    }
  }

  enableMintButton() {
    this.mintDisabled = false;
    this.quantitySelectorTarget.disabled = false;
    this.quantitySelectorTarget.classList.remove("dropdown-disabled");
    this.quantitySelectorTarget.classList.add("dropdown");

    this.mintButtonTarget.classList.add("button");
    this.mintButtonTarget.classList.remove("button-disabled");
  }

  calculateMintPrice() {
    const tokenPrice = this.discountedPrice || this.price;
    return tokenPrice * Math.max(0, this.quantity);
  }

  renderMintPrice() {
    const price = this.calculateMintPrice();
    const mintPrice = ethers.utils.formatEther(`${price}`);
    const priceSnippet = mintPrice > 0 ? `${mintPrice} ETH` : "Free";
    if (this.hasMintPriceTarget) {
      this.mintPriceTarget.innerText = priceSnippet;
    } else {
      const priceEl = this.element.getElementsByClassName("stats__price")[0];
      if (priceEl) {
        priceEl.innerText = priceSnippet;
      }
    }
  }

  async getRemainingMints() {
    let response;

    if (this.signature) {
      if (this.simpleWhiteListValue) {
        try {
          response = await this.contract
            .connect(window.provider.getSigner())
            .allowedMintCount(window.destinationWallet);
        } catch (e) {
          Sentry.captureException(e);
        }
      } else {
        if (this.restrictedQuantityValue) {
          response = await this.contract
            .connect(window.provider.getSigner())
            .allowedWhitelistMintCount(
              window.destinationWallet,
              this.maxMint,
              this.signature
            );
        } else {
          try {
            response = await this.contract
              .connect(window.provider.getSigner())
              .allowedMintCount(window.destinationWallet);
          } catch (e) {
            Sentry.captureException(e);
          }
        }
      }
    } else {
      response = await this.contract
        .connect(window.provider.getSigner())
        .allowedMintCount(window.destinationWallet);
    }
    return parseInt(response, 10);
  }

  renderMint() {
    if (this.hasMintContainerTarget)
      this.mintContainerTarget.classList.remove("hidden");
    if (this.hasConnectContainerTarget)
      this.connectContainerTarget.classList.add("hidden");
  }

  hideMint() {
    if (this.hasMintContainerTarget)
      this.mintContainerTarget.classList.add("hidden");
    if (this.hasConnectContainerTarget)
      this.connectContainerTarget.classList.remove("hidden");
    if (this.hasWalletTextTarget)
      this.walletTextTarget.innerText = CONNECT_BUTTON_CONNECT;
  }

  setMintButtonLabel(s) {
    if (this.hasMintButtonTarget) this.mintButtonTarget.innerText = s;
  }
}
