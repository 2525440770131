import { Application } from "@hotwired/stimulus";
import Sortable from "stimulus-sortable";
import CharacterCounter from "stimulus-character-counter";
import TextareaAutogrow from "stimulus-textarea-autogrow";
import ScrollTo from "stimulus-scroll-to";
import Notification from "stimulus-notification";
import Audio from "stimulus-audio";

const application = Application.start();

// Configure Stimulus development experience
application.warnings = true;
application.debug = false;
window.Stimulus = application;

export { application };

// Import and register all TailwindCSS Components
import {
  Alert,
  Autosave,
  Dropdown,
  Modal,
  Tabs,
  Popover,
  Toggle,
  Slideover,
} from "tailwindcss-stimulus-components";

application.register("alert", Alert);
application.register("audio", Audio);
application.register("autosave", Autosave);
application.register("character-counter", CharacterCounter);
application.register("dropdown", Dropdown);
application.register("modal", Modal);
application.register("notifications", Notification);
application.register("popover", Popover);
application.register("scroll-to", ScrollTo);
application.register("slideover", Slideover);
application.register("sortable", Sortable);
application.register("tabs", Tabs);
application.register("textarea-autogrow", TextareaAutogrow);
application.register("toggle", Toggle);
