export const getValue = async (contract, method, defaultValue) => {
  let response;

  try {
    response = await contract[method]();
  } catch (e) {
    return defaultValue;
  }

  return response;
};

export const getInt = async (contract, method, defaultValue) => {
  let response;

  try {
    response = await contract[method]();
  } catch (e) {
    return defaultValue;
  }

  return parseInt(response, 10);
};
