import { ethers } from "ethers";
import * as UAuthWeb3Modal from "@uauth/web3modal";
import Web3Modal from "web3modal";
import UAuthSPA from "@uauth/js";
import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletLink from "walletlink";

export const uauthOptions = {
  clientID: "1a88e3f8-ad7e-4794-b9f4-551e4e2e5224",
  redirectUri: window.location.href,
  scope: "openid wallet",
};

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: "f6615c57d87d47ba966c0e29930a3957", // required
      qrcodeModalOptions: {
        mobileLinks: ["rainbow", "metamask", "ledger", "argent", "trust"],
      },
    },
  },
  "custom-uauth": {
    display: UAuthWeb3Modal.display,
    connector: UAuthWeb3Modal.connector,
    options: uauthOptions,
    package: UAuthSPA,
  },
  walletlink: {
    package: WalletLink, // Required
    options: {
      appName: "Mint with Mason", // Required
      infuraId: "f6615c57d87d47ba966c0e29930a3957",
      chainId: 1,
      appLogoUrl: null,
      darkMode: false,
    },
  },
};

export const attemptReconnect = async (
  chainChanged,
  accountsChanged,
  disconnect
) => {
  const web3Modal = new Web3Modal({
    cacheProvider: true, // optional
    providerOptions, // required
  });

  UAuthWeb3Modal.registerWeb3Modal(web3Modal);

  if (web3Modal.cachedProvider) {
    console.log("Cached Provider Detected");

    const provider = await connectWallet(
      chainChanged,
      accountsChanged,
      disconnect
    );
    window.provider = provider;

    let event = new CustomEvent("wallet:reconnect");
    window.dispatchEvent(event);

    event = new CustomEvent("wallet:connect");
    window.dispatchEvent(event);
  }
};

export const connectWallet = async (
  chainChanged,
  accountsChanged,
  disconnect
) => {
  const web3Modal = new Web3Modal({
    cacheProvider: true, // optional
    providerOptions, // required
  });

  UAuthWeb3Modal.registerWeb3Modal(web3Modal);

  const instance = await web3Modal.connect();
  window.instance = instance;

  instance.on("accountsChanged", accountsChanged);
  instance.on("chainChanged", chainChanged);
  instance.on("disconnect", disconnect);

  const provider = new ethers.providers.Web3Provider(instance);

  return provider;
};

export const getAddress = async (provider) => {
  const signer = provider.getSigner();
  const address = await signer.getAddress();

  return address;
};

export const disconnectProvider = async (provider) => {
  if (provider.close) await provider.close();

  const web3Modal = new Web3Modal({
    cacheProvider: false, // optional
    providerOptions, // required
  });

  UAuthWeb3Modal.registerWeb3Modal(web3Modal);

  if (web3Modal.cachedProvider === "custom-uauth") {
    const uauth = UAuthWeb3Modal.getUAuth(UAuthSPA, uauthOptions);
    await uauth.logout();
  }

  web3Modal.clearCachedProvider();
};

export const checkNetwork = (chainId) => {
  switch (chainId) {
    case 1:
      console.log("Connected to Mainnet");
      document.getElementById("mainnetBanner").classList.add("hidden");
      break;
    case 0x1:
    default:
      console.log("Connected to Testnet");
      document.getElementById("mainnetBanner").classList.remove("hidden");
      break;
  }
};
