export const trackReel = (token_id, event) => {
  return fetch("/gallery/track_reel", {
    method: "POST",
    headers: {
      "X-CSRF-Token": document.head
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      token_id: token_id,
      event: event,
    }),
  }).then((response) => response.json());
};
