import { Controller } from "@hotwired/stimulus";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
  AsYouType,
} from "libphonenumber-js";
import { createMintNotification } from "../requests/mint_notification";

export default class extends Controller {
  static targets = [
    "input",
    "button",
    "buttonIcon",
    "buttonLabel",
    "form",
    "successMessage",
  ];

  connect() {}

  initialize() {}

  format() {
    this._unsetErrorState();

    const value = this.inputTarget.value;

    this.inputTarget.value = new AsYouType("US").input(value);
  }

  async submit() {
    if (this.validate()) {
      this._enableLoadingState();

      const response = await createMintNotification(this.inputTarget.value);

      if (response.success) {
        this._showSuccessMessage();
      } else {
        this._disableLoadingState();
      }
    }
  }

  validate() {
    const value = this.inputTarget.value;

    if (isPossiblePhoneNumber(value, "US") && isValidPhoneNumber(value, "US")) {
      return true;
    } else {
      this._setErrorState();
      return false;
    }
  }

  _showSuccessMessage() {
    this.formTarget.classList.add("hidden");
    this.successMessageTarget.classList.remove("hidden");
  }

  _setErrorState() {
    this.inputTarget.classList.add("mason-button__input-error");
    this.buttonTarget.classList.add("mason-button__input-disabled");
    this.buttonTarget.disabled = false;
  }

  _unsetErrorState() {
    this.inputTarget.classList.remove("mason-button__input-error");
    this.buttonTarget.classList.remove("mason-button__input-disabled");
    this.buttonTarget.disabled = false;
  }

  _enableLoadingState() {
    this.buttonLabelTarget.classList.add("hidden");
    this.buttonIconTarget.classList.remove("hidden");
    this.buttonTarget.classList.add("mason-button__input-disabled");
    this.buttonTarget.disabled = true;
  }

  _disableLoadingState() {
    this.buttonLabelTarget.classList.remove("hidden");
    this.buttonIconTarget.classList.add("hidden");
    this.buttonTarget.classList.remove("mason-button__input-disabled");
    this.buttonTarget.disabled = false;
  }

  _setButtonLabel(text) {
    this.buttonLabelTarget.innerText = text;
  }
}
