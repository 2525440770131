import { getAddress, uauthOptions } from "./connect_wallet";
import UAuthSPA from "@uauth/js";

export const getEnsAddress = async (provider) => {
  let ensAddress;

  try {
    ensAddress = await provider.lookupAddress(await getAddress(provider));
  } catch (e) {
    // User does not have ENS
  }

  return ensAddress;
};

export const getUdAddress = async () => {
  let udAddress;

  try {
    const userInfo = await new UAuthSPA(uauthOptions).user();

    console.log(userInfo);
    return userInfo.sub;
  } catch (e) {
    // User does not have UD
  }

  return udAddress;
};
