import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    index: Number,
    slideInterval: Number,
    autoplay: String,
    dynamicSize: String,
  };
  static get targets() {
    return ["slide"];
  }
  connect() {
    if (this.dynamicSizeValue !== "no") {
      this.setContainerHeight();
    }

    window.onresize = this.setContainerHeight.bind(this);

    if (this.autoplayValue === "yes") {
      this.startSlideShow();
    }
  }

  setContainerHeight() {
    let heights = [];
    this.slideTargets.forEach((element, index) => {
      // We need to make the elements display:block to get their height, so we temporarily make them
      // invisible

      element.classList.add("active");
      element.classList.remove("hidden");
      heights.push(element.scrollHeight);

      element.classList.add("hidden");
      element.classList.remove("active");
    });

    this.slideTargets[0].classList.add("active");

    let maxHeight = Math.max(...heights);

    this.element.style.height = maxHeight + "px";
  }

  indexValueChanged() {
    this.showCurrentSlide();
  }
  next() {
    this.nextSlide();
  }
  nextSlide() {
    if (this.indexValue < this.slideTargets.length - 1) {
      this.indexValue++;
    } else {
      this.indexValue = 0;
    }
  }
  resumeSlideShow() {
    if (this.autoplayValue === "yes") {
      this.startSlideShow();
    }
  }
  startSlideShow() {
    this.slideShowTimer = setInterval(() => {
      this.nextSlide();
    }, this.slideIntervalValue);
  }
  stopSlideShow() {
    if (this.slideShowTimer) {
      clearInterval(this.slideShowTimer);
    }
  }
  disconnect() {
    this.stopSlideShow();
  }
  previous() {
    if (this.indexValue > 0) {
      this.indexValue--;
    } else {
      this.indexValue = this.slideTargets.length - 1;
    }
  }
  showCurrentSlide() {
    this.slideTargets.forEach((element, index) => {
      //element.hidden = index != this.indexValue;
      var isHidden = index != this.indexValue;
      if (isHidden) {
        element.classList.remove("active");
      } else {
        element.classList.add("active");
      }
    });
  }
}
