export const verifyAddress = (
  walletAddress,
  contractAddress,
  artworkId,
  chainId
) => {
  return fetch(`${window.masonConfig.urlBase}/site/eggnog`, {
    method: "POST",
    headers: {
      "X-CSRF-Token": document.head
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      wallet_address: walletAddress,
      contract_address: contractAddress,
      artwork_id: artworkId,
      chain_id: chainId,
    }),
  }).then((response) => response.json());
};
