import { Controller } from "@hotwired/stimulus";
import { verifyAddress } from "../requests/eggnog_verify_address";
import { logTransaction } from "../requests/log_transaction";
import { trackEventWithContract } from "../utils/google_analytics";
import * as Sentry from "@sentry/browser";
import { ethers } from "ethers";
import { getValue, getInt } from "../utils/contract_helpers";

const CONNECT_BUTTON_CONNECT = "Connect Wallet";
const CONNECT_BUTTON_CONNECTING = "Connecting...";
const SALE_NOT_STARTED = "Sale Not Started";
const MINT_BUTTON_DEFAULT = "Claim";
const MINT_BUTTON_MINTING = "Claiming...";
const MINT_BUTTON_WAITING = "Processing...";
const MINT_BUTTON_MINTED = "Success!";
const MINT_BUTTON_INSUFFICIENT_FUNDS = "Insufficient Funds";
const MINT_BUTTON_SOLD_OUT = "All Gone";
const MINT_BUTTON_LIMIT_REACHED = "Already Claimed";
const MINT_BUTTON_NOT_ON_WHITELIST = "Wallet not Eligible";

export default class extends Controller {
  static values = {
    contract: String,
    contractAddress: String,
    price: String,
    restrictedQuantity: Boolean,
    artworkId: Number,
  };

  static targets = [
    "connectText",
    "mintContainer",
    "connectContainer",
    "errorContainer",
    "successContainer",
    "mintPrice",
    "mintButton",
    "connectWalletButton",
    "errorMessage",
    "supplyDisplay",
  ];

  async connect() {
    console.log("[MintButton] Connecting");

    if (this.contractValue) {
      let { ERC721ABI, ERC721ByteCode } = await import(
        `../contracts/${this.contractValue}/contract`
      );
      this.contractABI = JSON.parse(ERC721ABI);
      this.contractByteCode = ERC721ByteCode;
    }

    if (this.priceValue) {
      this.price = ethers.utils.parseUnits(this.priceValue);
    }

    this.mintDisabled = true;
    this.quantity = 1;
  }

  async connectWallet() {
    // We ask our Wallet Connect controller to connect the wallet
    const event = new CustomEvent("mint:connect");
    window.dispatchEvent(event);
  }

  async onConnect() {
    console.log("[MintButton] Connected");

    await this.getMintStatus();
  }

  onDisconnect() {
    console.log("[MintButton] Disconnected");
    this.renderDisconnected();
  }

  renderConnected() {
    if (this.hasConnectContainerTarget)
      this.connectContainerTarget.classList.add("hidden");
    if (this.hasErrorContainerTarget)
      this.errorContainerTarget.classList.add("hidden");
    if (this.hasSuccessContainerTarget)
      this.successContainerTarget.classList.add("hidden");
    if (this.hasMintContainerTarget)
      this.mintContainerTarget.classList.remove("hidden");
  }

  renderConnecting() {
    this.setConnectButtonLabel(CONNECT_BUTTON_CONNECTING);
  }

  renderDisconnected() {
    this.setConnectButtonLabel(CONNECT_BUTTON_CONNECT);

    if (this.hasConnectContainerTarget)
      this.connectContainerTarget.classList.remove("hidden");
    if (this.hasErrorContainerTarget)
      this.errorContainerTarget.classList.add("hidden");
    if (this.hasSuccessContainerTarget)
      this.successContainerTarget.classList.add("hidden");
    if (this.hasMintContainerTarget)
      this.mintContainerTarget.classList.add("hidden");
  }

  setConnectButtonLabel(s) {
    if (this.hasConnectTextTarget) {
      this.connectTextTarget.innerText = s;
    }
  }

  async getMintStatus() {
    this.renderConnecting();

    if (!this.contractAddressValue) return false;

    let network = await window.provider.getNetwork();

    let response = await verifyAddress(
      window.destinationWallet,
      this.contractAddressValue,
      this.artworkIdValue,
      network.chainId
    );

    this.mintStatus = response;

    const { onSale, signature, saleStartsAt } = response;

    if (!onSale) {
      this.setConnectButtonLabel(SALE_NOT_STARTED);
      setTimeout(this.getMintStatus.bind(this), 60 * 1000);
    } else {
      this.signature = signature;
      this.discountedPrice = 0;
      this.maxMint = 1;

      this.contract = new ethers.Contract(
        this.contractAddressValue,
        this.contractABI,
        window.provider
      );

      await this.populateDetails();

      this.renderConnected();
    }
  }

  async mint() {
    if (this.mintDisabled) return;

    this.hideError();
    this.hideSuccess();

    if (typeof this.contract === "undefined") {
      this.connectWallet();
    } else {
      this.setLoadingState();
      this.setMintButtonLabel(MINT_BUTTON_MINTING);

      let quantity = this.quantity || 1;
      let price = this.discountedPrice || this.price;
      let value = this.calculateMintPrice();

      let tx;

      try {
        const discountValue = this.restrictedQuantityValue
          ? this.maxMint
          : price;

        tx = await this.contract
          .connect(window.provider.getSigner())
          .mint(`${this.artworkIdValue}`, this.signature, {
            value: String(value),
          });

        console.log(tx);

        let transactionHash = tx["hash"] || tx["transactionHash"];
        try {
          logTransaction(
            transactionHash,
            window.destinationWallet,
            quantity,
            String(value),
            this.contractAddress
          );
        } catch (e) {
          console.log("Error Logging TX", e);
        }

        trackEventWithContract(
          "mint",
          this.contractAddressValue,
          window.destinationWallet,
          transactionHash,
          quantity
        );

        this.setMintButtonLabel(MINT_BUTTON_WAITING);
        await tx.wait();

        this.setMintButtonLabel(MINT_BUTTON_MINTED);
        this.showSuccess();
        this.populateDetails();
        this.cancelLoadingState();
      } catch (e) {
        Sentry.captureException(e);

        if (e["message"].indexOf("User denied transaction signature") >= 0) {
          this.setMintButtonLabel(MINT_BUTTON_DEFAULT);
          this.populateDetails();
          this.cancelLoadingState();
        } else if (e["message"].indexOf("insufficient funds") >= 0) {
          this.setMintButtonLabel(MINT_BUTTON_INSUFFICIENT_FUNDS);
          this.cancelLoadingState();
        } else if (
          e["message"].indexOf("ERROR_EXCEEDS_MAX_PER_WALLET") >= 0 ||
          e["message"].indexOf("Minting limit exceeded") >= 0
        ) {
          this.setMintButtonLabel(MINT_BUTTON_LIMIT_REACHED);
          this.populateDetails();
          this.cancelLoadingState();
          this.showError(
            "You have minted the maximum amount of tokens allowed per wallet"
          );
        } else if (
          e["message"].indexOf("ERROR_EXCEEDS_MAX_SUPPLY") >= 0 ||
          e["message"].indexOf("Exceeds max supply") >= 0
        ) {
          this.setMintButtonLabel(MINT_BUTTON_SOLD_OUT);
          this.populateDetails();
          this.cancelLoadingState();
        } else {
          Sentry.captureException(e);
          this.setMintButtonLabel(MINT_BUTTON_DEFAULT);
          this.cancelLoadingState();
          this.showError("Transaction Failed - Please Try Again");
          return false;
        }
      }
    }
  }

  setLoadingState() {
    this.disableMintButton();
  }

  cancelLoadingState() {
    this.enableMintButton();
  }

  showError(e) {
    const event = new CustomEvent("mint:error", { detail: e });
    window.dispatchEvent(event);
  }

  showSuccess() {
    if (this.hasSuccessContainerTarget)
      this.successContainerTarget.classList.add("hidden");

    const event = new CustomEvent("mint:success", {
      address: this.destinationWallet,
    });
    window.dispatchEvent(event);
  }

  hideError() {
    if (this.hasErrorContainerTarget)
      this.errorContainerTarget.classList.add("hidden");

    const event = new CustomEvent("mint:error:hide");
    window.dispatchEvent(event);
  }

  hideSuccess() {
    if (this.hasSuccessContainerTarget)
      this.successContainerTarget.classList.add("hidden");

    const event = new CustomEvent("mint:success:hide");
    window.dispatchEvent(event);
  }

  async populateDetails() {
    console.log("[MintButton] populateDetails");

    this.whitelistIsActive = await getValue(
      this.contract,
      "whitelistIsActive",
      false
    );
    this.maxSupply = await getInt(this.contract, "MAX_SUPPLY");
    this.totalSupply = await getInt(this.contract, "totalSupply");
    this.remainingSupply = this.maxSupply - this.totalSupply;
    this.allowedMintCount = await this.getRemainingMints();
    this.price = 0;
    this._updateSupply();

    if (!this.whitelistIsActive) {
      this.setMintButtonLabel(SALE_NOT_STARTED);
      this.disableMintButton();
    }

    let whitelistEligible = await this._whitelistEligible();

    if (!whitelistEligible) {
      this.setMintButtonLabel(MINT_BUTTON_NOT_ON_WHITELIST);
      this.disableMintButton();
    }

    if (whitelistEligible) {
      if (
        (typeof this.maxSupply === "undefined" || this.remainingSupply > 0) &&
        this.allowedMintCount > 0
      ) {
        this.enableMintButton();
        this.renderMint();
      } else {
        if (this.allowedMintCount <= 0) {
          this.setMintButtonLabel(MINT_BUTTON_LIMIT_REACHED);
        } else {
          this.setMintButtonLabel(MINT_BUTTON_SOLD_OUT);
        }
        this.disableMintButton();
      }
    }

    setTimeout(this.populateDetails.bind(this), 60000);
  }

  _updateSupply() {
    // Target doesn't register due to TurboFrame, we need to do this the old fashioned way
    const supplyEl = this.element.getElementsByClassName("stats__count")[0];
    if (supplyEl) {
      let text = "";
      if (!this.maxSupply) {
        text = "Unlimited Supply";
      } else {
        text = `${this.remainingSupply.toLocaleString()} available`;
      }
      supplyEl.innerText = text;
    }
  }

  async _whitelistEligible() {
    if (!this.whitelistIsActive) return false;
    if (!this.signature) return false;

    let response;

    try {
      response = await this.contract
        .connect(window.provider.getSigner())
        .checkWhitelist(`${this.artworkIdValue}`, this.signature);
    } catch (e) {
      Sentry.captureException(e);
      response = false;
    }

    return response;
  }

  disableMintButton() {
    this.mintDisabled = true;
    this.mintButtonTarget.classList.add("button-disabled");
    this.mintButtonTarget.classList.remove("button");
  }

  enableMintButton() {
    this.mintDisabled = false;
    this.mintButtonTarget.classList.add("button");
    this.mintButtonTarget.classList.remove("button-disabled");
  }

  calculateMintPrice() {
    const tokenPrice = this.discountedPrice || this.price;
    return tokenPrice * Math.max(0, this.quantity);
  }

  async getRemainingMints() {
    let response;

    if (this.signature) {
      try {
        response = await this.contract
          .connect(window.provider.getSigner())
          .allowedMintCount(window.destinationWallet, `${this.artworkIdValue}`);
      } catch (e) {
        Sentry.captureException(e);
      }
    } else {
      response = 0;
    }

    return parseInt(response, 10);
  }

  renderMint() {
    if (this.hasMintContainerTarget)
      this.mintContainerTarget.classList.remove("hidden");
    if (this.hasConnectContainerTarget)
      this.connectContainerTarget.classList.add("hidden");
  }

  hideMint() {
    if (this.hasMintContainerTarget)
      this.mintContainerTarget.classList.add("hidden");
    if (this.hasConnectContainerTarget)
      this.connectContainerTarget.classList.remove("hidden");
    if (this.hasWalletTextTarget)
      this.walletTextTarget.innerText = CONNECT_BUTTON_CONNECT;
  }

  setMintButtonLabel(s) {
    if (this.hasMintButtonTarget) this.mintButtonTarget.innerText = s;
  }
}
