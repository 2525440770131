export const checkCaptureEligibility = (walletAddress, chainId, uuid) => {
  return fetch(`${window.masonConfig.urlBase}/site/captures`, {
    method: "POST",
    headers: {
      "X-CSRF-Token": document.head
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      wallet_address: walletAddress,
      chain_id: chainId,
      uuid: uuid
    }),
  }).then((response) => response.json());
};
