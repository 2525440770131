import { Controller } from "@hotwired/stimulus";
import * as Sentry from "@sentry/browser";
import {
  connectWallet,
  getAddress,
  disconnectProvider,
  checkNetwork,
} from "../utils/connect_wallet";
import { formatAddress } from "../utils/formatters";
import { v4 as uuidv4 } from "uuid";
import { checkCaptureEligibility } from "../requests/check_capture";
import { captureData } from "../requests/capture_data";
import { getEnsAddress } from "../utils/web3utils";

const UI_STRINGS = {
  CONNECT_BUTTON_CONNECT: "Connect Wallet",
  CONNECT_BUTTON_DISCONNECT: "Disconnect",
  CONNECT_BUTTON_CONNECTING: "Connecting...",
  SUBMIT_BUTTON_SUBMITTED: "Entry Submitted",
  SUBMIT_BUTTON_SUBMITTING: "Submitting...",
  SUBMIT_BUTTON_SUBMIT: "Submit",
  ERROR_NOT_ELIGIBLE: "You do not have the required token to play.",
};

export default class extends Controller {
  static values = {
    contract: String,
    tokenId: String,
  };

  static targets = [
    "introContainer",
    "connectContainer",
    "errorContainer",
    "successContainer",
    "formContainer",
    "connectButton",
    "connectButtonText",
    "walletDisconnect",
    "submitButton",
    "teamTitansEntry",
    "teamCharaEntry",
    "teamAstraEntry",
    "teamDracoEntry",
    "teamAyaEntry",
    "teamTitansEntrySelected",
    "teamCharaEntrySelected",
    "teamAstraEntrySelected",
    "teamDracoEntrySelected",
    "teamAyaEntrySelected",
    "entryUsername",
    "entryEmail",
  ];

  connect() {
    console.log("[CaptureController] Connected");

    this.resetConnection();
    this.submitting = false;
    this.uuid = uuidv4();

    this.indices = {
      "team-titans": 0,
      "team-chara": 0,
      "team-astra": 0,
      "team-draco": 0,
      "team-aya": 0,
    };
  }

  isConnected() {
    return this.provider && this.destinationWallet;
  }

  resetConnection() {
    this.provider = null;
    this.destinationWallet = null;
    this.connectingWallet = false;
    this.ensAddress = null;
    this.uuid = uuidv4();
  }

  async connectWallet() {
    console.log("connectWallet");

    if (this.connectingWallet) return;

    if (this.isConnected()) {
      await disconnectProvider(this.provider);
      this.resetConnection();
      this.renderDisconnected();
    } else {
      try {
        this.connectingWallet = true;
        this.renderConnecting();
        this.provider = await connectWallet(
          checkNetwork,
          this.connectWallet.bind(this),
          this.resetConnection.bind(this)
        );
        this.destinationWallet = await getAddress(this.provider);
        this.ensAddress = await getEnsAddress(this.provider);
      } catch (e) {
        Sentry.captureException(e);
        this.resetConnection();
      }

      let networkDetails = await this.provider.getNetwork();
      checkNetwork(networkDetails.chainId);

      await this.addressCheck(networkDetails.chainId);

      this.renderConnected();

      document.getElementById("connect").scrollIntoView();

      if (this.eligibility["capture_id"]) {
        this.uuid = this.eligibility["uuid"];
        this.showEntry();
      } else if (this.eligibility["error"]) {
        this.showErrorMessage(this.eligibility["error"]);
      } else {
        if (Object.keys(this.eligibility).length > 0) {
          console.log("eligibility", this.eligibility);
          this.showForm();
        } else {
          this.showErrorMessage(UI_STRINGS.ERROR_NOT_ELIGIBLE);
        }
      }
    }
  }

  async addressCheck(chainId) {
    const response = await checkCaptureEligibility(
      this.destinationWallet,
      chainId,
      this.uuid
    );

    this.eligibility = response;
    console.log(response);
  }

  async submit(event) {
    console.log("submit");
    event.preventDefault();

    if (this.submitting) return;

    if (this.hasSubmitButtonTarget) {
      console.log(this);
      this.submitButtonTarget.innerText = UI_STRINGS.SUBMIT_BUTTON_SUBMITTING;
      this.submitButtonTarget.disabled = true;
      this.submitButtonTarget.classList.add("disabled");
    }

    // Read form fields and POST data to server.
    const form = event.target;
    const formData = new FormData(form);
    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });

    console.log(data);

    try {
      await captureData(
        this.uuid,
        this.destinationWallet,
        this.contractValue,
        data
      );
      if (this.hasSubmitButtonTarget)
        this.submitButtonTarget.innerText = UI_STRINGS.SUBMIT_BUTTON_SUBMITTED;
      this.submitButtonTarget.classList.remove("icon-submit");
      this.showSuccessMessage();
    } catch (e) {
      Sentry.captureException(e);
      this.submitButtonTarget.disabled = true;
      this.submitButtonTarget.classList.add("disabled");
      this.submitButtonTarget.innerText = UI_STRINGS.SUBMIT_BUTTON_SUBMIT;
      this.showErrorMessage(e.message);
    }

    return false;
  }

  _renderTeam(name, target) {
    const entries = this.eligibility[name];

    if (entries === undefined) {
      target.classList.add("disabled");
    } else {
      if (entries.length > 1) {
        target.classList.add("multiple");

        for (let i = 1; i < entries.length; i++) {
          const img = new Image();
          img.src = entries[i].image_url;
        }

        target.addEventListener("click", () => {
          this._changeEntry(name, target);
        });
      }
      target.src = entries[0].image_url;
      target.alt = "Akutar #" + entries[0].token_id;

      const input = target.nextElementSibling;
      input.value = entries[0].token_id;
    }
  }

  _changeEntry(name, target) {
    const currentIndex = this.indices[name];
    const newIndex = (currentIndex + 1) % this.eligibility[name].length;

    this.indices[name] = newIndex;

    const entry = this.eligibility[name][newIndex];
    target.src = entry.image_url;
    target.alt = "Akutar #" + entry.token_id;

    const input = target.nextElementSibling;
    input.value = entry.token_id;
  }

  showForm() {
    this._renderTeam("team-titans", this.teamTitansEntryTarget);
    this._renderTeam("team-chara", this.teamCharaEntryTarget);
    this._renderTeam("team-astra", this.teamAstraEntryTarget);
    this._renderTeam("team-draco", this.teamDracoEntryTarget);
    this._renderTeam("team-aya", this.teamAyaEntryTarget);

    hideAllTargets("introContainer");
    showAllTargets("formContainer");
  }

  showEntry() {
    this._renderTeam("team-titans", this.teamTitansEntrySelectedTarget);
    this._renderTeam("team-chara", this.teamCharaEntrySelectedTarget);
    this._renderTeam("team-astra", this.teamAstraEntrySelectedTarget);
    this._renderTeam("team-draco", this.teamDracoEntrySelectedTarget);
    this._renderTeam("team-aya", this.teamAyaEntrySelectedTarget);

    const { email, username } = this.eligibility["data"];

    updateAllTargets("entryEmail", (el) => {
      el.value = email;
    });

    updateAllTargets("entryUsername", (el) => {
      if (username) el.value = username;
    });

    hideAllTargets("introContainer");
    showAllTargets("entryContainer");
  }

  showSuccessMessage() {
    showAllTargets("successContainer");
  }

  showErrorMessage(text) {
    updateAllTargets("errorText", (el) => {
      el.innerText = text;
    });
    showAllTargets("errorContainer");
  }

  renderConnected() {
    this.connectingWallet = false;

    updateAllTargets("connectButtonText", (el) => {
      el.innerText = this.ensAddress || formatAddress(this.destinationWallet);
    });
  }

  renderConnecting() {
    updateAllTargets("connectButtonText", (el) => {
      el.innerText = UI_STRINGS.CONNECT_BUTTON_CONNECTING;
    });
  }

  renderDisconnected() {
    updateAllTargets("connectButtonText", (el) => {
      el.innerText = UI_STRINGS.CONNECT_BUTTON_CONNECT;
      el.classList.remove("hidden");
    });

    hideAllTargets("walletDisconnect");
    hideAllTargets("errorContainer");
    hideAllTargets("successContainer");
    hideAllTargets("historyContainer");
    hideAllTargets("formContainer");
    showAllTargets("introContainer");
  }

  connectMouseOver() {
    if (!this.provider) return false;

    hideAllTargets("connectButtonText");
    showAllTargets("walletDisconnect");
  }

  connectMouseOut() {
    if (!this.provider) return false;

    showAllTargets("connectButtonText");
    hideAllTargets("walletDisconnect");
  }
}

const updateAllTargets = (name, action) => {
  document.querySelectorAll("[data-capture-target]").forEach((el) => {
    if (el.dataset.captureTarget === name) action(el);
  });
};

const showAllTargets = (name) => {
  document.querySelectorAll("[data-capture-target]").forEach((el) => {
    if (el.dataset.captureTarget === name) el.classList.remove("hidden");
  });
};

const hideAllTargets = (name) => {
  document.querySelectorAll("[data-capture-target]").forEach((el) => {
    if (el.dataset.captureTarget === name) el.classList.add("hidden");
  });
};
