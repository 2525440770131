export const requestPass = (walletAddress, ensAddress) => {
  return fetch("/wallet/add", {
    method: "POST",
    headers: {
      "X-CSRF-Token": document.head
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      wallet_address: walletAddress,
      ens_address: ensAddress,
    }),
  }).then((response) => {
    if (response.redirected) {
      window.location.href = response.url;
    } else {
      return response.blob();
    }
  });
};
