export const logRedemption = (uuid, address, contract, tx_hash, data, redeemable_id) => {
  return fetch(`${window.masonConfig.urlBase}/site/redemption`, {
    method: "POST",
    headers: {
      "X-CSRF-Token": document.head
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      redemption: {
        uuid,
        redeemable_id,
        address,
        contract,
        tx_hash,
        data,
      },
    }),
  }).then((response) => response.json());
};
