import { Controller } from "@hotwired/stimulus";
import {
  connectWallet,
  getAddress,
  disconnectProvider,
  checkNetwork,
} from "../utils/connect_wallet";
import { verifyStatus } from "../requests/verify_early_majority";
import { logTransaction } from "../requests/log_transaction";
import { trackEvent } from "../utils/google_analytics";
import * as Sentry from "@sentry/browser";
import { ethers } from "ethers";
import { getValue, getInt } from "../utils/contract_helpers";
import { formatAddress } from "../utils/formatters";
import { getEnsAddress } from "../utils/web3utils";
import {
  hideAllTargets,
  showAllTargets,
  updateAllTargets,
} from "../utils/stimulus_helpers";

let destinationWallet;

const CONNECT_BUTTON_CONNECT = "Connect Wallet";
const CONNECT_BUTTON_CONNECTING = "Connecting...";
const MINT_BUTTON_DEFAULT = "Mint";
const MINT_BUTTON_MINTING = "Minting...";
const MINT_BUTTON_WAITING = "Processing...";
const MINT_BUTTON_MINTED = "Minted";
const MINT_BUTTON_INSUFFICIENT_FUNDS = "Insufficient Funds";
const MINT_BUTTON_SOLD_OUT = "Sold Out";
const MINT_BUTTON_LIMIT_REACHED = "Limit Reached";
const MINT_BUTTON_NOT_ON_WHITELIST = "Not on the list";
const STATUS_NOT_YET_PHASE_0 =
  "Member Minting September 7. Allowlist Minting September 8. Public Minting September 9.";
const STATUS_NOT_YET_PHASE_1 =
  "Currently in Presale. Public Minting September 9.";
const STATUS_NOT_YET_PHASE_2 = "Public Minting September 9.";

export default class extends Controller {
  static values = {
    currentPhase: Number,
    nextPhaseStartsAt: String,
  };

  static targets = [
    "connectButton",
    "errorContainer",
    "errorMessage",
    "mintButton",
    "mintPrice",
    "mintContainer",
    "phaseText",
    "connectContainer",
    "phaseOneContainer",
    "phaseThreeContainer",
    "phaseTwoContainer",
    "quantitySelector",
    "successContainer",
    "walletDisconnect",
    "walletText",
    "mintCap",
    "statusText",
    "arrowContainer",
    "claimButton",
  ];

  async connect() {
    console.log("[EM] Connecting");
    let { ERC721ABI, ERC721ByteCode } = await import(
      "../contracts/earlymajority/contract"
    );
    this.contractABI = JSON.parse(ERC721ABI);
    this.contractByteCode = ERC721ByteCode;

    this.mintDisabled = true;
    this.claimDisabled = false;
    this.quantity = 1;
  }

  async connectWallet() {
    if (this.connectingWallet) return;

    if (this.provider && this.destinationWallet) {
      await disconnectProvider(this.provider);

      // Clear internal variables
      this.provider = null;
      this.destinationWallet = null;

      // Update the UI to reflect disconnected state
      this.renderDisconnected();
    } else {
      this.renderConnecting();

      try {
        let provider = await connectWallet(
          checkNetwork,
          this.connectWallet.bind(this),
          this.onDisconnect.bind(this)
        );
        this.provider = provider;

        this.destinationWallet = await getAddress(this.provider);
        this.ensAddress = await getEnsAddress(this.provider);
      } catch (e) {
        // Something went wrong and we were unable to connect wallet. Switch
        // to disconnected state.
        Sentry.captureException(e);
        this.renderDisconnected();
      }

      let networkDetails = await this.provider.getNetwork();
      checkNetwork(networkDetails.chainId);

      await this.getMintStatus();

      this.renderConnected();
    }
  }

  async onDisconnect() {
    this.provider = null;
    this.destinationWallet = null;
    this.renderDisconnected();
  }

  connectMouseOver() {
    if (!this.provider) return false;

    if (this.hasWalletTextTarget) this.walletTextTarget.classList.add("hidden");
    if (this.hasWalletDisconnectTarget)
      this.walletDisconnectTarget.classList.remove("hidden");
  }

  connectMouseOut() {
    if (!this.provider) return false;

    if (this.hasWalletTextTarget)
      this.walletTextTarget.classList.remove("hidden");
    if (this.hasWalletDisconnectTarget)
      this.walletDisconnectTarget.classList.add("hidden");
  }

  renderConnected() {
    this.connectingWallet = false;

    if (this.hasWalletTextTarget)
      this.walletTextTarget.innerText =
        this.ensAddress || formatAddress(this.destinationWallet);

    this.renderMint();
  }

  renderConnecting() {
    this.connectingWallet = true;
    this.setConnectButtonLabel(CONNECT_BUTTON_CONNECTING);

    updateAllTargets("mint-em", "walletText", (el) => {
      console.log(el);
      el.innerText = CONNECT_BUTTON_CONNECTING;
    });
  }

  renderDisconnected() {
    this.connectingWallet = false;

    if (this.hasWalletTextTarget) {
      this.walletTextTarget.innerText = CONNECT_BUTTON_CONNECT;
      this.walletTextTarget.classList.remove("hidden");
    }
    if (this.hasWalletDisconnectTarget)
      this.walletDisconnectTarget.classList.add("hidden");

    this.setConnectButtonLabel(CONNECT_BUTTON_CONNECT);
    this.hideMint();
  }

  setConnectButtonLabel(s) {
    updateAllTargets("mint-em", "connectButton", (el) => {
      el.innerText = s;
    });
  }

  async getMintStatus() {
    let response = await verifyStatus(
      this.destinationWallet,
      this.contractValue
    );

    this.mintStatus = response;
    console.log(response);

    const {
      contractAddress,
      currentPhase,
      nextPhaseStartsAt,
      memberSignature,
      memberPrice,
      allowlistSignature,
      allowlistPrice,
      claimSignature,
      claimQuantity,
    } = response;

    this.contractAddress = contractAddress;
    this.signature = memberSignature || allowlistSignature;
    this.claimSignature = claimSignature;
    this.claimQuantity = claimQuantity;

    if (memberSignature) this.mintPrice = memberPrice;
    if (allowlistSignature && !memberSignature) this.mintPrice = allowlistPrice;

    if (this.currentPhaseValue != currentPhase) {
      this.currentPhaseValue = currentPhase;
    }

    // Parse ISO8601 timestamp and run a method when the next phase starts
    if (nextPhaseStartsAt) {
      let nextPhaseStartsAtDate = new Date(nextPhaseStartsAt);
      let now = new Date();
      let diff = nextPhaseStartsAtDate.getTime() - now.getTime();
      let seconds = Math.floor(diff / 1000);
      setTimeout(this.getMintStatus.bind(this), 60000);
    }

    if (contractAddress) {
      this.contractAddress = contractAddress;
      this.contract = new ethers.Contract(
        contractAddress,
        this.contractABI,
        this.provider
      );
      await this.populateDetails();
    }
  }

  _updateClaimButton(s) {
    if (this.claimDisabled) {
      this.claimButtonTarget.disabled = true;
      this.claimButtonTarget.classList.add("disabled");
    } else {
      this.claimButtonTarget.disabled = false;
      this.claimButtonTarget.classList.remove("disabled");
    }

    this.claimButtonTarget.innerText = s;
  }

  async claim() {
    if (this.claimDisabled || !this.claimSignature || !this.claimSignature)
      return;
    this.hideError();
    this.hideSuccess();

    if (typeof this.contract === "undefined") {
      this.connectWallet();
    } else {
      this.claimDisabled = true;
      this._updateClaimButton("Claiming");

      let tx;
      try {
        tx = await this.contract
          .connect(this.provider.getSigner())
          .claim(this.claimQuantity, this.claimSignature);

        await tx.wait();

        this.hideClaim();
        this.showSuccess();
      } catch (e) {
        Sentry.captureException(e);
        console.log(e);
        this.showError("There was an error claiming - please try again later");
      }
    }
  }

  async mint() {
    if (this.mintDisabled) return;

    this.hideError();
    this.hideSuccess();

    if (typeof this.contract === "undefined") {
      this.connectWallet();
    } else {
      this.setLoadingState();
      this.setMintButtonLabel(MINT_BUTTON_MINTING);

      let quantity = this.quantity || 1;
      let price = this.mintPrice || this.price;
      console.log("Price", this.mintPrice);
      let value = this.calculateMintPrice();

      let tx;

      try {
        if (this.signature) {
          tx = await this.contract
            .connect(this.provider.getSigner())
            .whitelistMint(price, quantity, this.signature, {
              value: String(this.mintPrice),
            });

          console.log(tx);
        } else {
          tx = await this.contract
            .connect(this.provider.getSigner())
            .mint(quantity, {
              value: String(value),
            });
        }

        let transactionHash = tx["hash"] || tx["transactionHash"];
        try {
          logTransaction(
            transactionHash,
            this.destinationWallet,
            quantity,
            String(value),
            this.contractAddress
          );
        } catch (e) {
          console.log("Error Logging TX", e);
        }

        trackEvent("mint", this.destinationWallet, transactionHash, quantity);

        this.setMintButtonLabel(MINT_BUTTON_WAITING);
        await tx.wait();

        this.setMintButtonLabel(MINT_BUTTON_MINTED);
        this.showSuccess();
        this.cancelLoadingState();
      } catch (e) {
        console.log(e);

        if (e["message"].indexOf("User denied transaction signature") >= 0) {
          this.setMintButtonLabel(MINT_BUTTON_DEFAULT);
          this.populateDetails();
          this.cancelLoadingState();
        } else if (e["message"].indexOf("insufficient funds") >= 0) {
          this.setMintButtonLabel(MINT_BUTTON_INSUFFICIENT_FUNDS);
          this.cancelLoadingState();
        } else if (
          e["message"].indexOf("ERROR_EXCEEDS_MAX_PER_WALLET") >= 0 ||
          e["message"].indexOf("Minting limit exceeded") >= 0
        ) {
          this.setMintButtonLabel(MINT_BUTTON_LIMIT_REACHED);
          this.populateDetails();
          this.cancelLoadingState();
          this.showError(
            "You have minted the maximum amount of tokens allowed per wallet"
          );
        } else if (
          e["message"].indexOf("ERROR_EXCEEDS_MAX_SUPPLY") >= 0 ||
          e["message"].indexOf("Exceeds max supply") >= 0
        ) {
          this.setMintButtonLabel(MINT_BUTTON_SOLD_OUT);
          this.populateDetails();
          this.cancelLoadingState();
        } else {
          Sentry.captureException(e);
          console.log(e);
          this.setMintButtonLabel(MINT_BUTTON_DEFAULT);
          this.cancelLoadingState();
          this.showError("Transaction Failed - Please Try Again");
          return false;
        }
      }
    }
  }

  setLoadingState() {
    this.disableMintButton();
  }

  cancelLoadingState() {
    this.enableMintButton();
  }

  showError(e) {
    this.errorMessageTarget.innerText = e;

    const event = new CustomEvent("mint:error");
    window.dispatchEvent(event);
  }

  showClaim() {
    const event = new CustomEvent("claim:show");
    window.dispatchEvent(event);
  }

  hideClaim() {
    const event = new CustomEvent("claim:hide");
    window.dispatchEvent(event);
  }

  showSuccess() {
    const event = new CustomEvent("mint:success");
    window.dispatchEvent(event);
  }

  hideError() {
    const event = new CustomEvent("mint:error:hide");
    window.dispatchEvent(event);
  }

  hideSuccess() {
    const event = new CustomEvent("mint:success:hide");
    window.dispatchEvent(event);
  }

  async populateDetails() {
    console.log("Querying contract...");

    this.whitelistIsActive = await getValue(
      this.contract,
      "whitelistIsActive",
      false
    );

    if (!this.signature && this.currentPhaseValue !== 3) {
      if (this.currentPhaseValue === 0) {
        this.statusTextTarget.innerText = STATUS_NOT_YET_PHASE_0;
        this.arrowContainerTarget.classList.add("invisible");
      } else if (this.currentPhaseValue == 1) {
        this.statusTextTarget.innerText = STATUS_NOT_YET_PHASE_1;
        this.arrowContainerTarget.classList.add("invisible");
      } else {
        this.statusTextTarget.innerText = STATUS_NOT_YET_PHASE_2;
        this.arrowContainerTarget.classList.add("invisible");
      }

      this.hideMint();
      this.connectContainerTarget.classList.add("hidden");
      return;
    } else {
      this.maxSupply = await getInt(this.contract, "MAX_SUPPLY");
      this.totalSupply = await getInt(this.contract, "totalSupply");

      this.remainingSupply = this.maxSupply - this.totalSupply;
      this.allowedMintCount = await this.getRemainingMints();
      this.price = await this._fetchPrice();

      this.renderMintPrice();

      this._updateQuantitySelector();

      if (this.remainingSupply > 0) {
        if (this.allowedMintCount > 0) {
          this.enableMintButton();
          this.renderMint();
        } else {
          this.setMintButtonLabel(MINT_BUTTON_LIMIT_REACHED);
          this.disableMintButton();
        }
      } else {
        this.setMintButtonLabel(MINT_BUTTON_SOLD_OUT);
        this.disableMintButton();
      }
    }

    if (this.claimSignature && this.claimQuantity) {
      const claimsEnabled = await getValue(
        this.contract,
        "claimingIsActive",
        false
      );

      if (claimsEnabled) {
        let validClaim = false;
        let hasUnclaimedTokens = await this.contract.hasUnclaimedTokens(
          this.destinationWallet
        );
        try {
          validClaim = await this.contract
            .connect(this.provider.getSigner())
            .checkClaimlist(this.claimQuantity, this.claimSignature);
        } catch (e) {
          Sentry.captureException(e);
        }

        if (validClaim && hasUnclaimedTokens) {
          this.showClaim();
        }
      }
    }

    setTimeout(this.populateDetails.bind(this), 60000);
  }

  async _fetchPrice() {
    let price = parseInt(await getValue(this.contract, "PRICE", 0), 10);
    try {
      if (this.signature && this.mintPrice) {
        const discountValid = await this.contract
          .connect(this.provider.getSigner())
          .checkPrice(this.mintPrice, this.signature);
        if (discountValid) {
          price = this.mintPrice;
        }
      }
    } catch (e) {
      Sentry.captureException(e);
    }

    return price;
  }

  disableMintButton() {
    this.mintDisabled = true;
    this.quantitySelectorTarget.disabled = true;
    this.quantitySelectorTarget.classList.add("dropdown-disabled");
    this.quantitySelectorTarget.classList.remove("dropdown");

    this.mintButtonTarget.classList.add("button-disabled");
    this.mintButtonTarget.classList.remove("button");
  }

  _updateQuantitySelector() {
    const maxQuantity = Math.min(this.remainingSupply, this.allowedMintCount);
    let element = this.quantitySelectorTarget;

    let maxValue = 0;
    for (let i = 0; i < element.options.length; i++) {
      let value = parseInt(element.options[i].value);
      if (value > maxValue) {
        maxValue = value;
      }
    }

    if (maxValue !== maxQuantity) {
      element.innerHTML = "";
      let elements = "";
      for (var i = 0; i < maxQuantity; i++) {
        var opt = i + 1;
        elements += '<option value="' + opt + '">' + opt + "</option>";
      }

      element.innerHTML = elements;
    }
  }

  updatePrice() {
    this.quantity = this.quantitySelectorTarget.value;
    this.renderMintPrice();
  }

  enableMintButton() {
    this.mintDisabled = false;
    this.quantitySelectorTarget.disabled = false;
    this.quantitySelectorTarget.classList.remove("dropdown-disabled");
    this.quantitySelectorTarget.classList.add("dropdown");

    this.mintButtonTarget.classList.add("button");
    this.mintButtonTarget.classList.remove("button-disabled");
  }

  calculateMintPrice() {
    return this.price * Math.max(0, this.quantity);
  }

  renderMintPrice() {
    if (this.hasMintPriceTarget) {
      let price = this.mintPrice || this.calculateMintPrice();
      let mintPrice = ethers.utils.formatEther(`${price}`);

      let priceSnippet;
      priceSnippet = `${mintPrice} Ξ`;

      this.mintPriceTarget.innerText = priceSnippet;
    }
  }

  async getRemainingMints() {
    let response;

    if (this.signature) {
      response = await this.contract
        .connect(this.provider.getSigner())
        .allowedMintCount(this.destinationWallet);
    } else {
      response = await this.contract.allowedMintCount(this.destinationWallet);
    }

    return parseInt(response, 10);
  }

  renderMint() {
    if (!this.signature && this.currentPhaseValue !== 3) return;
    showAllTargets("mint-em", "mintContainer");
    hideAllTargets("mint-em", "connectContainer");
  }

  hideMint() {
    hideAllTargets("mint-em", "mintContainer");
    showAllTargets("mint-em", "connectContainer");
    updateAllTargets("mint-em", "walletText", (el) => {
      el.innerText = CONNECT_BUTTON_CONNECT;
    });
  }

  setMintButtonLabel(s) {
    if (this.hasMintButtonTarget) this.mintButtonTarget.innerText = s;
  }
}
