import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggleable"];

  connect() {
    // Any clicks outside the controller’s element can
    // be setup to either add a 'hidden' class or
    // remove a 'open' class etc.
    //useClickOutside(this);
  }

  toggle(e) {
    e.preventDefault();

    this.toggleableTargets.forEach((target) => {
      target.classList.toggle(
        target.dataset.cssClass || target.dataset.cssclass
      );
    });
  }

  clickOutside(event) {
    if (this.data.get("clickOutside") === "add") {
      this.toggleableTargets.forEach((target) => {
        target.classList.add(
          target.dataset.cssClass || target.dataset.cssclass
        );
      });
    } else if (this.data.get("clickOutside") === "remove") {
      this.toggleableTargets.forEach((target) => {
        target.classList.remove(
          target.dataset.cssClass || target.dataset.cssclass
        );
      });
    }
  }
}
