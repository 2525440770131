// TODO: Consider if we need to split this between admin and sites.

// Import and register all your controllers from the importmap under controllers/*

import { application } from "controllers/application";
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

// Eager load all controllers defined in the import map under controllers/**/*_controller

// // Lazy load controllers as they appear in the DOM (remember not to preload controllers in import map!)
// lazyLoadControllersFrom("controllers", application)

import AdminController from "./admin_controller";
import CaptureController from "./capture_controller";
import ContractController from "./contract_controller.js";
import CountdownController from "./countdown_controller";
import FireworksMintController from "./fireworks_mint_controller";
import FlipdownController from "./flipdown_controller";
import GateController from "./gate_controller";
import LocalTimeController from "./local_time_controller";
import MintV2Controller from "./mint_v2_controller";
import Notification from "stimulus-notification";
import PassController from "./pass_controller";
import RedeemController from "./redeem_controller";
import ScrollTo from "stimulus-scroll-to";
import ToggleController from "./toggle_controller";
import VerifyController from "./verify_controller";
import WalletConnect from "./wallet_connect_controller";
import Audio from "stimulus-audio";

console.log("Initializing Stimulus controllers...");

application.register("audio", Audio);
const context = require.context("controllers", true, /_controller\.js$/);
application.load(definitionsFromContext(context));

export const connectWallet = async () => {
  if (typeof window.ethereum !== "undefined") {
    console.log("MetaMask is installed!");
  }

  if (typeof window.ethereum !== "undefined") {
    web3 = new Web3(window.ethereum);

    try {
      await window.ethereum.enable();
    } catch (e) {
      return false;
    }
  }
};

export async function requestAccounts() {
  const accounts = await ethereum.request({ method: "eth_requestAccounts" });
  return accounts;
}

export async function personalSign(account, message) {
  const signature = await ethereum.request({
    method: "personal_sign",
    params: [message, account],
  });
  return signature;
}

export async function getUuidByAccount(account) {
  const response = await fetch("/accounts/" + account);
  const nonceJson = await response.json();
  if (!nonceJson) return null;
  const uuid = nonceJson[0].eth_nonce;
  return uuid;
}
