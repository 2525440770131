import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["time"];

  connect() {}

  initialize() {
    setInterval(this._updateTime.bind(this), 1000);
  }

  _updateTime() {
    const time = this._getTimeRemaining();

    let string = "";
    if (time.days > 0) string += `${time.days} days, `;
    if (time.hours > 0) string += `${time.hours} hours, `;
    if (time.minutes > 0) string += `${time.minutes} minutes, `;
    if (time.seconds > 0) string += `${time.seconds} seconds`;

    this.element.textContent = string;
  }

  _getTimeRemaining() {
    const t = Date.parse(this.element.dataset.time) - Date.parse(new Date());

    // Escape hatch for when countdown is done
    if (t < 0) window.location.reload();

    const seconds = Math.floor((t / 1000) % 60);
    const minutes = Math.floor((t / 1000 / 60) % 60);
    const hours = Math.floor((t / (1000 * 60 * 60)) % 24);
    const days = Math.floor(t / (1000 * 60 * 60 * 24));

    return {
      total: t,
      days,
      hours,
      minutes,
      seconds,
    };
  }
}
