import { Controller } from "@hotwired/stimulus";
import { connectWallet, requestAccounts } from "./index";

// TODO: Switch this to Ethers, make nicer

const MASON_WALLET = "0x391Fd74D878489981015635959c7C970E13ad057";

const GEN2_CONTRACTS = [
  "fireworks",
  "breakclub",
  "glyphic",
  "womeninvc",
  "default_v2",
  "daohouse",
  "secretfightclub",
  "floor75",
];

export default class extends Controller {
  static targets = [
    "owner",
    "connectButton",
    "mintButton",
    "mintPrice",
    "totalMints",
    "publicSupply",
    "maxTokens",
    "reservedTokens",
    "publicListMaxMint",
    "balanceOf",
    "saleIsActive",
    "priceInput",
    "publicMaxInput",
    "reservedTokenMintCount",
    "maxMultiMintInput",
    "baseUri",
    "baseUriInput",
  ];

  async connect() {
    let { ERC721ABI, ERC721ByteCode } = await import(
      `../contracts/${window.config.contractTemplate}/contract`
    );

    this.contractABI = JSON.parse(ERC721ABI);
    this.contractByteCode = ERC721ByteCode;

    this.contractAddress = window.config.address;

    console.log(`Using Contract: ${this.contractAddress}`);
    this.connectWallet();
  }

  async connectWallet() {
    let destinationWallet = await connectWallet();
    const accounts = await requestAccounts();

    this.destinationWallet = accounts[0];

    console.log(`Connected ${this.destinationWallet}`);

    await this.loadContract();
    await this.loadContractDetails();
  }

  async loadContract() {
    this.contract = new web3.eth.Contract(
      this.contractABI,
      this.contractAddress
    );
    window.contract = this.contract;
  }

  async loadContractDetails() {
    let price,
      minted,
      supply,
      owner,
      maxTokens,
      reservedTokens,
      publicListMaxMint,
      saleIsActive,
      baseUri,
      maxMultiMint;

    if (GEN2_CONTRACTS.includes(window.config.contractTemplate)) {
      // TODO: We should wrap all these try and catch in a "Call contract method, or return default" function" to avoid repeating code, needs to be type aware for reuse on mint sites
      try {
        reservedTokens = await this.contract.methods
          .MAX_RESERVED_SUPPLY()
          .call();
      } catch (e) {
        reservedTokens = 0;
      }

      try {
        publicListMaxMint = await this.contract.methods.MAX_PER_WALLET().call();
      } catch (e) {
        publicListMaxMint = "N/A";
      }

      try {
        maxMultiMint = await this.contract.methods.MAX_MULTIMINT().call();
      } catch (e) {
        maxMultiMint = "1";
      }
    } else {
      maxTokens = await this.contract.methods.MAX_TOKENS().call();
      publicListMaxMint = await this.contract.methods
        .publicListMaxMint()
        .call();
      reservedTokens = await this.contract.methods.RESERVED_TOKENS().call();
      supply = await this.contract.methods.PUBLIC_TOKENS().call();
    }

    if (GEN2_CONTRACTS.includes(window.config.contractTemplate)) {
      maxTokens = await this.contract.methods.MAX_SUPPLY().call();
      minted = await this.contract.methods.totalSupply().call();

      try {
        price = await this.contract.methods.PRICE().call();
      } catch (e) {
        price = "0";
      }
    } else {
      minted = await this.contract.methods.totalSaleSupply().call();
      price = await this.contract.methods.TOKEN_PRICE().call();
    }

    // Generic Params, common across all contracts
    owner = await this.contract.methods.owner().call();

    try {
      saleIsActive = await this.contract.methods.saleIsActive().call();
    } catch (e) {
      saleIsActive = true;
    }

    baseUri = await this.contract.methods.baseTokenURI().call();

    this.totalMintsTarget.innerText = `${minted}`;
    if (this.hasPublicSupplyTarget)
      this.publicSupplyTarget.innerText = `${supply}`;
    this.mintPriceTarget.innerText = `${Web3.utils.fromWei(price)} ETH`;
    this.ownerTarget.innerText = `${owner}`;
    if (this.hasMaxTokensTarget)
      this.maxTokensTarget.innerText = `${maxTokens}`;
    if (this.hasReservedTokensTarget)
      this.reservedTokensTarget.innerText = `${reservedTokens}`;
    if (this.hasPublicListMaxMintTarget)
      this.publicListMaxMintTarget.innerText = `${publicListMaxMint}`;
    this.saleIsActiveTarget.innerText = `${saleIsActive ? "Yes" : "No"}`;
    this.priceInputTarget.value = `${Web3.utils.fromWei(price)}`;
    if (this.hasPublicMaxInputTarget)
      this.publicMaxInputTarget.value = `${publicListMaxMint}`;
    if (this.hasMaxMultiMintInputTarget)
      this.maxMultiMintInputTarget.value = `${maxMultiMint}`;
    if (this.hasBaseUriTarget) this.baseUriTarget.innerText = `${baseUri}`;
    if (this.hasBaseUriInputTarget)
      this.baseUriInputTarget.value = `${baseUri}`;
  }

  async flipSaleState() {
    await this.contract.methods
      .flipSaleState()
      .send({ from: this.destinationWallet });
    await this.loadContractDetails();
  }

  async release() {
    await this.contract.methods
      .release(this.destinationWallet)
      .send({ from: this.destinationWallet });
    await this.contract.methods
      .release(MASON_WALLET)
      .send({ from: this.destinationWallet });
  }

  async releaseOwner() {
    await this.contract.methods
      .release(window.config.payoutAddress || this.destinationWallet)
      .send({ from: this.destinationWallet });
  }

  async releaseSplits() {
    window.config.splitAddresses.forEach(async (address) => {
      await this.contract.methods
        .release(address)
        .send({ from: this.destinationWallet });
    });
  }

  async releasePayment(event) {
    await this.contract.methods
      .release(event.params.wallet)
      .send({ from: this.destinationWallet });
  }

  async adjustBaseUri() {
    const newUri = this.baseUriInputTarget.value;

    await this.contract.methods
      .setBaseURI(newUri)
      .send({ from: this.destinationWallet });
    await this.loadContractDetails();
  }

  async adjustPrice() {
    const newPrice = Web3.utils.toWei(
      `${this.priceInputTarget.value}`,
      "ether"
    );

    await this.contract.methods
      .setPrice(newPrice)
      .send({ from: this.destinationWallet });
    await this.loadContractDetails();
  }

  async mintReserved() {
    const tokenCount = parseInt(this.reservedTokenMintCountTarget.value);

    await this.contract.methods
      .mintReservedToken(tokenCount)
      .send({ from: this.destinationWallet });
    await this.loadContractDetails();
  }

  async adjustPublicListMax() {
    const newValue = parseInt(this.publicMaxInputTarget.value);

    await this.contract.methods
      .setPublicListMaxMint(newValue)
      .send({ from: this.destinationWallet });

    await this.loadContractDetails();
  }

  async mint() {
    this.setLoadingState();

    if (
      typeof this.destinationWallet === "undefined" ||
      typeof this.mintStatus["contractAddress"] === "undefined"
    ) {
      this.mintButtonTarget.innerText = "Connecting Wallet";
      await this.connectWallet();
    }

    this.setButtonLabel("Minting");

    let web3 = new Web3(window.ethereum);
    const { contractAddress, mintPrice, metadataUrl } = this.mintStatus;
    const value = Web3.utils.toWei(`${mintPrice}`, "ether");

    try {
      const mint = this.contract.methods.mintToken(1, metadataUrl);
      // const mint = contract.methods.flipSaleState()

      console.log(mint);

      const gas = await mint.estimateGas({
        from: this.destinationWallet,
        value: value,
      });

      const tx = await mint.send({ from: this.destinationWallet, value, gas });
      console.log(tx);

      this.setButtonLabel("Congratulations!");

      await this.populateDetails(); //confetti
      this.cancelLoadingState();
    } catch (e) {
      let errorMessage = e.message.match(/\"execution reverted: (.+)\"/)[1];

      this.setButtonLabel(errorMessage);

      await this.populateDetails();
      this.cancelLoadingState();
    }
  }
}
