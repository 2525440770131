export const createMintNotification = (phone) => {
  return fetch(`${window.masonConfig.urlBase}/site/notification`, {
    method: "POST",
    headers: {
      "X-CSRF-Token": document.head
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      mint_notification: {
        phone,
      },
    }),
  }).then((response) => response.json());
};
