import { Controller } from "@hotwired/stimulus";
import strftime from "strftime";

export default class extends Controller {
  static values = {
    datetime: String,
    format: String,
  };

  initialize() {
    this._renderTime();
  }

  _renderTime() {
    const date = new Date(this.datetimeValue);
    this.element.textContent = strftime(this.formatValue, date);
  }
}
