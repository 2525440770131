// Checks for the ethereum network version. If not connected to the mainnet
// it will attempt to show an element with the ID `mainnetBanner`.
//
// This would typcially be excuted on pageload (we use a 1 sec timeout to allow
// for initialization)  and subecribed to the `window.ethereum.on('networkChanged')` event

export const checkNetwork = () => {
  if (typeof ethereum !== "undefined") {
    const netId = window.ethereum.networkVersion;

    switch (netId) {
      case "1":
        console.log("Connected to Mainnet");
        document.getElementById("mainnetBanner").classList.add("hidden");
        break;
      default:
        console.log("Connected to Testnet");
        document.getElementById("mainnetBanner").classList.remove("hidden");
        break;
    }
  }
};
