// Checks for the MetaMask (or compatible extension). If it is not installed,
// it will display a message to the user defined in an element with the
// ID `metaMaskBanner`.
//
// Typically this would be executed once on initialization.

export const checkMetaMask = () => {
  if (typeof ethereum === "undefined") {
    document.getElementById("metaMaskBanner").classList.remove("hidden");
  }
};
