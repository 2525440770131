import Cookies from "js-cookie";

export const trackEvent = (
  event_name,
  wallet_address,
  transaction_hash,
  minted_count
) => {
  const referrer = Cookies.get("referrer");

  try {
    gtag("event", event_name, {
      referrer: `${referrer}`,
      wallet_address: `${wallet_address}`,
      transaction_hash: `${transaction_hash}`,
      minted_count: `${minted_count}`,
    });
  } catch (e) {
    // do nothing
  }
};

export const trackEventWithContract = (
  event_name,
  contract_address,
  wallet_address,
  transaction_hash,
  minted_count
) => {
  const referrer = Cookies.get("referrer");

  try {
    gtag("event", event_name, {
      contract_address: `${contract_address}`,
      referrer: `${referrer}`,
      wallet_address: `${wallet_address}`,
      transaction_hash: `${transaction_hash}`,
      minted_count: `${minted_count}`,
    });
  } catch (e) {
    // do nothing
  }
};
