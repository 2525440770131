import { Controller } from "@hotwired/stimulus";
import { checkOpensea } from "../requests/check_opensea";
import { checkNetwork } from "../utils/check_network";
import { checkMetaMask } from "../utils/check_metamask";
import { connectWallet, requestAccounts } from "./index";
import { validateTokens } from "../requests/validate_tokens";
import { fetchReel } from "../requests/fetch_reel";
import { trackReel } from "../requests/track_reel";
import { ERC721ABI } from "../contracts/fireworks/contract";

export default class extends Controller {
  static values = {
    contract: String,
  };

  static targets = [
    "connectButton",
    "loadingPanel",
    "errorPanel",
    "artPanel",
    "artPanelContent",
    "videoPanel",
    "playedPanel",
    "playButton",
    "tokenId",
    "sequenceId",
    "finPanel",
    "sequenceImage",
  ];

  connect() {
    this.contractABI = JSON.parse(ERC721ABI);
    setTimeout(() => {
      checkNetwork();
    }, 1000);

    if (typeof ethereum !== "undefined") {
      window.ethereum.on("networkChanged", checkNetwork.bind(this));
      window.ethereum.on("accountsChanged", this.connectWallet.bind(this));
    }
  }

  async connectWallet() {
    this._setButtonText("Connecting...");
    const wallet = await connectWallet();
    const accounts = await requestAccounts();

    this.wallet = accounts[0];

    this._setButtonText(
      this.wallet.substring(0, 10) +
        "..." +
        this.wallet.substring(this.wallet.length - 6)
    );

    this.queryWallet();
  }

  async queryWallet() {
    if (!this.wallet) {
      console.log("No wallet connected");
      return;
    }

    this._showPanel("loading");

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(
      this.contractValue,
      this.contractABI,
      provider
    );

    let balance = await contract
      .connect(provider.getSigner())
      .balanceOf(this.wallet);
    let tokenIds = await contract
      .connect(provider.getSigner())
      .tokensOfOwner(this.wallet);
    tokenIds = tokenIds.map((tokenId) => tokenId.toNumber());
    // remove any 0 values from tokenIds array
    tokenIds = tokenIds.filter((tokenId) => tokenId !== 0);

    console.log(`${this.wallet} has ${balance} tokens`);
    console.log("tokenIds", tokenIds);

    if (tokenIds.length !== balance.toNumber()) {
      console.log("Error: tokenIds.length !== balance.toNumber()");
    }

    if (tokenIds.length === 0) {
      console.log("No tokens found");
      this._hidePanel("loading");
      this._showPanel("error");
    } else {
      this._showPanel("loading");
      this.requestReels(tokenIds);
    }
  }

  async requestReels(tokenIds) {
    // Fetch Reels from endpoint in Controller, sending wallet address and tokenIds
    let response = await validateTokens(this.wallet, tokenIds);

    // Response:
    // [ { tokenId: 1, watched: true, watchedAt: '2019-01-01', image_url: '' }, ... ]
    this.artPanelContentTarget.innerHTML = "";

    if (response.length === 0) {
      this._hidePanel("loading");
    } else {
      response.forEach((reel) => {
        this.artPanelContentTarget.append(
          Token(
            reel.tokenId,
            reel.watched,
            reel.imageUrl,
            reel.sequenceId,
            reel.frameId
          )
        );
      });

      this._hidePanel("loading");
      this._showPanel("art");
    }
  }

  async selectReel(event) {
    this._hidePanel("played");

    // remove the selected class from all elements with the class token
    let tokens = document.querySelectorAll(".token");
    tokens.forEach((token) => {
      // remove the class selected from the parent element
      token.parentElement.classList.remove("selected");
    });
    // Add class to event target
    event.target.parentElement.classList.add("selected");
    this.playButtonTarget.classList.remove("hidden");

    // Clickhandler for when an unwatched reel is clicked
    // endpoint will return a video url (and anything else a player may need)
    //  - this could be signed Cloudfront URL
    //  - if video has been watched, error response will be returned and we should
    //    show a message to the;
    // Response should initialize video player

    const url = event.target.dataset.imageUrl;
    this.sequenceImageTarget.src = url;

    const response = await fetchReel(this.wallet, event.target.dataset.tokenId);
    if (response.error) {
      console.log("Error: " + response.error);
      this._showPanel("played");
    } else {
      this.selectedUrl = response.url;
      this.selectedTokenId = response.token_id;

      this.frameId = response.frame_id;
      this.tokenId = response.token_id;
      this.sequenceId = response.sequence_id;

      this.sequenceIdTarget.innerText = response.sequence_id;
      this.tokenIdTarget.innerText = response.frame_id;
    }
  }

  playReel() {
    if (this.tokenId === undefined) return false;

    if (confirm(`View IFICE ${this.sequenceId}-${this.frameId}`)) {
      this._showPanel("video");
      this._hidePanel("art");
      document.body.classList.add("noscroll");
      window.scrollTo(0, 0);
      this.startReel(this.selectedTokenId, this.selectedUrl);
    }
  }

  startReel(tokenId, src) {
    let frame = document.getElementById("videoPlayer");
    frame.src = src;
    const player = Stream(document.getElementById("videoPlayer"));

    player.addEventListener("canplay", function () {
      setTimeout(() => {
        frame.classList.remove("invisible");
        player.play();
      }, 3000);

      trackReel(tokenId, "start");
    });
    player.addEventListener(
      "ended",
      function () {
        console.log("fin");

        let context = this;
        trackReel(tokenId, "end");
        frame.classList.add("invisible");
        //wait 3 seconds
        setTimeout(() => {
          document.body.classList.remove("noscroll");
          context._showPanel("fin");
          context._hidePanel("video");
        }, 3000);
      }.bind(this)
    );
  }

  _setButtonText(text) {
    this.connectButtonTarget.innerText = text;
  }

  _hidePanel(name) {
    this[name + "PanelTarget"].classList.add("hidden");
  }

  _showPanel(name) {
    this[name + "PanelTarget"].classList.remove("hidden");
  }
}

const Token = (tokenId, watched, imageUrl, sequenceId, frameId) => {
  // create a new div element with a child p element
  let el = document.createElement("div");
  el.className = "token__container";

  let img = document.createElement("img");
  img.src = imageUrl;
  img.alt = tokenId;
  img.dataset.action = watched ? "" : "click->gate#selectReel";
  img.dataset.tokenId = tokenId;
  img.dataset.imageUrl = imageUrl;
  img.className = `token ${watched ? "watched" : ""}`;

  let text = document.createElement("p");
  text.innerText = `${sequenceId} - ${frameId}`;
  text.className = "text-sm text-center";

  // make img a child of the div
  el.appendChild(img);
  el.appendChild(text);

  return el;
};
