export const captureData = (uuid, address, contract, data) => {
  return fetch(`${window.masonConfig.urlBase}/site/capture`, {
    method: "POST",
    headers: {
      "X-CSRF-Token": document.head
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      capture: {
        uuid,
        address,
        contract,
        data,
      },
    }),
  }).then((response) => response.json());
};
