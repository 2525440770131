import { Controller } from "@hotwired/stimulus";
import { createContract } from "../requests/create_contract";
import { deployContract } from "../requests/deploy_contract";
import { checkNetwork } from "../utils/check_network";
import { ethers } from "ethers";
import {
  connectWallet,
  getAddress,
  disconnectProvider,
} from "../utils/connect_wallet";

const buttonLabels = {
  initial: "Deploy Contract",
  deploying: "Deploying",
  waiting: "Waiting for Confirmation",
  saving: "Saving",
};

const GEN2_CONTRACTS = [
  "breakclub",
  "daohouse",
  "default_v2",
  "floor75",
  "glyphic",
  "hannahgrey",
  "secretfightclub",
  "venice",
  "womeninvc",
  "airdrop",
  "daohouse-friends",
  "mason_base",
  "mystery_sneaker_box",
  "floor_app_pass",
  "earlymajority",
  "supporters_edition",
  "collectors_edition",
  "linkspfp",
  "genx-vip",
];

export default class extends Controller {
  static targets = ["deployButton"];

  async connect() {
    let { ERC721ABI, ERC721ByteCode } = await import(
      `../contracts/${window.config.contractTemplate}/contract`
    );

    this.contractABI = JSON.parse(ERC721ABI);
    this.contractByteCode = ERC721ByteCode;

    setTimeout(() => {
      checkNetwork();
    }, 1000);

    if (typeof ethereum !== "undefined") {
      window.ethereum.on("networkChanged", checkNetwork);
    }
  }

  initialize() {
    this._updateState(String(this.element.dataset.state));
  }

  _updateState(state) {
    this.state = state;
    this._updateLabel();
  }

  _updateLabel() {
    this.setButtonLabel(buttonLabels[this.state]);
  }

  setLoadingState() {
    document.getElementById("button-svg").classList.remove("hidden");
    this.deployButtonTarget.disabled = true;
  }

  cancelLoadingState() {
    document.getElementById("button-svg").classList.add("hidden");
    this.deployButtonTarget.disabled = false;
  }

  setButtonLabel(s) {
    document.getElementById("button-label").innerText = s;
  }

  async create() {
    this.setLoadingState();
    this._updateState("deploying");
    try {
      //const provider = new ethers.providers.Web3Provider(window.ethereum);
      let provider = await connectWallet(
        checkNetwork,
        () => {},
        () => {}
      );
      const signer = provider.getSigner();

      const factory = new ethers.ContractFactory(
        this.contractABI,
        this.contractByteCode,
        signer
      );

      const {
        tokenName,
        tokenSymbol,
        tokenPrice,
        tokensReserved,
        tokensForSale,
        baseUri,
        paymentWallets,
        paymentSplits,
      } = window.contractConfig;
      const payees = paymentWallets;
      const shares = paymentSplits;

      let deploymentParams = [
        tokenName,
        tokenSymbol,
        baseUri,
        payees,
        shares,
        Web3.utils.toWei(tokenPrice, "ether"),
        tokensForSale,
        tokensReserved,
      ];

      if (window.contractConfig.deploymentParams) {
        deploymentParams = window.contractConfig.deploymentParams;
      }

      console.log("deploymentParams", deploymentParams);

      let contract;

      if (GEN2_CONTRACTS.includes(window.config.contractTemplate)) {
        contract = await factory.deploy(...deploymentParams);
      } else {
        alert("This contract template is not supported.");
      }

      let transactionHash = contract.deployTransaction.hash;
      let networkVersion = window.ethereum.networkVersion;

      deployContract(
        this.deployButtonTarget.dataset.url,
        transactionHash,
        networkVersion
      );

      this._updateState("waiting");
      let response = await contract.deployTransaction.wait();

      this._updateState("saving");
      createContract(
        this.deployButtonTarget.dataset.url,
        contract.address
      ).then((data) => {
        window.location = this.deployButtonTarget.dataset.redirect;
      });
    } catch (error) {
      this._updateState("initial");
      this.cancelLoadingState();
      console.log(error);
    }
  }
}
