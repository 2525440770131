import { Controller } from "@hotwired/stimulus";
import { connectWallet, getAddress } from "../utils/connect_wallet";
import { requestPass } from "../requests/request_pass";
import * as Sentry from "@sentry/browser";
import streamSaver from "streamsaver";
import { getEnsAddress } from "../utils/web3utils";

let destinationWallet;

export default class extends Controller {
  static targets = ["button", "errorText"];

  async connect() {
    console.log("connected");
  }

  async request() {
    this.errorTextTarget.classList.add("hidden");

    await this.connectWallet();

    let response = await requestPass(this.destinationWallet, this.ensAddress);
    console.log(response);
    console.log("dssdg");
    if (response instanceof Blob) {
      let url = window.URL.createObjectURL(response);

      var pom = document.createElement("a");
      pom.setAttribute("href", url);
      pom.setAttribute("download", "pass.pkpass");

      if (document.createEvent) {
        var event = document.createEvent("MouseEvents");
        event.initEvent("click", true, true);
        pom.dispatchEvent(event);
      } else {
        pom.click();
      }
    } else {
    }
  }

  async connectWallet() {
    this.buttonTarget.innerText = "Requesting Pass";

    try {
      let provider = await connectWallet(
        () => {},
        () => {},
        () => {}
      );
      this.provider = provider;

      destinationWallet = await getAddress(this.provider);
      this.destinationWallet = destinationWallet;
    } catch (e) {
      // Something went wrong and we were unable to connect wallet. Switch
      // to disconnected state.
      Sentry.captureException(e);
      this.buttonTarget.innerText = "Add to Wallet";
    }

    this.ensAddress = await getEnsAddress(this.provider);
  }
}
