import { Modal } from "tailwindcss-stimulus-components";

export default class ErrorModal extends Modal {
  static targets = ["message"];

  connect() {
    super.connect();
  }

  _populateFields(data) {
    const { message } = data;

    if (this.hasMessageTarget) {
      this.messageTargets.forEach((target) => {
        target.innerText = message;
      });
    }
  }

  open(event) {
    this._populateFields(event.detail);
    super.open(event);
  }
}
