export const validateTokens = (walletAddress, tokens) => {
  return fetch("/gallery/validate_tokens", {
    method: "POST",
    headers: {
      "X-CSRF-Token": document.head
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      wallet_address: walletAddress,
      tokens: tokens,
    }),
  }).then((response) => response.json());
};
