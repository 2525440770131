export const logTransaction = (
  transactionHash,
  minter,
  quantity,
  amount,
  contract
) => {
  return fetch(`${window.masonConfig.urlBase}/site/mint`, {
    method: "POST",
    headers: {
      "X-CSRF-Token": document.head
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      sale: {
        transaction_hash: transactionHash,
        minter: minter,
        quantity: quantity,
        value: amount,
        contract: contract,
      },
    }),
  }).then((response) => response.json());
};
