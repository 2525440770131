export const deployContract = (url, transactionHash, networkVersion) => {
  return fetch(url, {
    method: "POST",
    headers: {
      "X-CSRF-Token": document.head
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      transaction_hash: transactionHash,
      network_version: networkVersion,
    }),
  }).then((response) => response.json());
};
