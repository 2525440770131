import { Modal } from "tailwindcss-stimulus-components";

export default class SuccessModal extends Modal {
  static targets = ["address", "contract", "tokenId", "openseaLink", "txHash"];

  connect() {
    super.connect();
  }

  _populateFields(data) {
    const { address, contract, tokenId } = data;

    if (this.hasAddressTarget) {
      this.addressTargets.forEach((target) => {
        target.innerText = address;
      });
    }

    if (this.hasContractTarget) {
      this.contractTargets.forEach((target) => {
        target.innerText = contract;
      });
    }

    if (this.hasTokenIdTarget) {
      this.tokenIdTargets.forEach((target) => {
        target.innerText = tokenId;
      });
    }

    if (this.hasOpenseaLinkTarget) {
      this.openseaLinkTargets.forEach((target) => {
        target.href = `https://opensea.io/assets/${contract}/${tokenId}`;
      });
    }
  }

  open(event) {
    this._populateFields(event.detail);
    super.open(event);
  }
}
