import { Controller } from "@hotwired/stimulus";
import Tick from "@pqina/flip";

export default class extends Controller {
  static targets = ["time"];

  connect() {}

  initialize() {
    Tick.DOM.create(document.getElementsByClassName("tick")[0], {
      didInit: this._initCounter.bind(this),
    });
  }

  _initCounter(tick) {
    var counter = Tick.count.down(this.element.dataset.time);
    counter.onupdate = function (value) {
      tick.value = value;
    };
    counter.onended = function () {
      window.location.reload();
    };
  }
}
