import { Controller } from "@hotwired/stimulus";
import {
  connectWallet,
  getAddress,
  disconnectProvider,
  checkNetwork,
  attemptReconnect,
} from "../utils/connect_wallet";
import { getEnsAddress, getUdAddress } from "../utils/web3utils";
import { formatAddress } from "../utils/formatters";
import * as Sentry from "@sentry/browser";

let destinationWallet;

const STRINGS = {
  CONNECT_BUTTON_CONNECT: "Connect Wallet",
  CONNECT_BUTTON_CONNECTING: "Connecting...",
  CONNECT_BUTTON_DISCONNECT: "Disconnect Wallet",
};

export default class extends Controller {
  static values = {
    walletPrefix: String,
    hideConnect: Boolean,
  };

  static targets = ["connectWalletButton", "walletText", "walletDisconnect"];

  async connect() {
    console.log("[WalletConnect] Initialized");
    this.resetGlobals();
    this.connectingWallet = false;

    await attemptReconnect(
      checkNetwork,
      this.connectWallet.bind(this),
      this.onDisconnect.bind(this)
    );
    if (window.provider) {
      await this.setGlobals();
      this.renderConnected();
    }
  }

  resetGlobals() {
    window.provider = null;
    window.destinationWallet = null;
    window.ensAddress = null;
    window.udAddress = null;
  }

  async setGlobals() {
    if (window.provider) {
      window.destinationWallet = await getAddress(window.provider);
      window.ensAddress = await getEnsAddress(window.provider);
    }
    window.udAddress = await getUdAddress();
  }

  async connectWallet() {
    console.log("[WalletConnect] Connecting wallet");
    if (this.connectingWallet) return;

    if (window.provider && window.destinationWallet) {
      await disconnectProvider(window.provider);

      this.resetGlobals();
      this.onDisconnect();
    } else {
      this.renderConnecting();

      try {
        console.log("[WalletConnect] Attempting to connect wallet");
        let provider = await connectWallet(
          checkNetwork,
          this.connectWallet.bind(this),
          this.onDisconnect.bind(this)
        );
        window.provider = provider;
        await this.setGlobals();
      } catch (e) {
        console.log("[WalletConnect] Error connecting wallet", e);
        // Something went wrong and we were unable to connect wallet. Switch
        // to disconnected state.
        Sentry.captureException(e);
        this.renderDisconnected();
      }
      let networkDetails = await window.provider.getNetwork();
      checkNetwork(networkDetails.chainId);

      const event = new CustomEvent("wallet:connect");
      window.dispatchEvent(event);

      this.renderConnected();
    }
  }

  onDisconnect() {
    const event = new CustomEvent("wallet:disconnect");
    window.dispatchEvent(event);

    this.resetGlobals();
    this.renderDisconnected();
  }

  connectMouseOver() {
    if (!window.provider) return false;

    if (this.hasWalletTextTarget) this.walletTextTarget.classList.add("hidden");
    if (this.hasWalletDisconnectTarget)
      this.walletDisconnectTarget.classList.remove("hidden");
  }

  connectMouseOut() {
    if (!window.provider) return false;

    if (this.hasWalletTextTarget)
      this.walletTextTarget.classList.remove("hidden");
    if (this.hasWalletDisconnectTarget)
      this.walletDisconnectTarget.classList.add("hidden");
  }

  renderConnected() {
    this.connectingWallet = false;

    if (this.hasWalletTextTarget)
      this.walletTextTarget.innerText = `${this.walletPrefixValue}${
        window.ensAddress ||
        window.udAddress ||
        formatAddress(window.destinationWallet)
      }`;
  }

  renderConnecting() {
    this.connectingWallet = true;
    this.setConnectButtonLabel(STRINGS["CONNECT_BUTTON_CONNECTING"]);
    if (this.hasWalletTextTarget)
      this.walletTextTarget.innerText = this.hideConnectValue
        ? ""
        : STRINGS["CONNECT_BUTTON_CONNECTING"];
  }

  renderDisconnected() {
    this.connectingWallet = false;

    if (this.hasWalletTextTarget)
      this.walletTextTarget.innerText = this.hideConnectValue
        ? ""
        : STRINGS["CONNECT_BUTTON_CONNECT"];
    if (this.hasWalletTextTarget)
      this.walletTextTarget.classList.remove("hidden");
    if (this.hasWalletDisconnectTarget)
      this.walletDisconnectTarget.classList.add("hidden");

    this.setConnectButtonLabel(STRINGS["CONNECT_BUTTON_CONNECT"]);
  }

  setConnectButtonLabel(s) {
    if (this.hasConnectButtonTarget) this.connectButtonTarget.innerText = s;
  }
}
