import { Controller } from "@hotwired/stimulus";
import {
  connectWallet,
  getAddress,
  disconnectProvider,
} from "../utils/connect_wallet";
import { getEnsAddress } from "../utils/web3utils";
import { verifyAddress } from "../requests/verify_address";
import { logTransaction } from "../requests/log_transaction";
import { trackEvent } from "../utils/google_analytics";
import * as Sentry from "@sentry/browser";
import { ethers } from "ethers";

let destinationWallet;

import { ERC721ABI, ERC721ByteCode } from "../contracts/milo/contract";

const CONNECT_BUTTON_CONNECT = "Connect Wallet";
const CONNECT_BUTTON_CONNECTING = "Connecting...";
const MINT_BUTTON_DEFAULT = "Mint Token";
const MINT_BUTTON_MINTING = "Minting...";
const MINT_BUTTON_WAITING = "Waiting...";
const MINT_BUTTON_MINTED = "Minted!";
const MINT_BUTTON_INSUFFICIENT_FUNDS = "Insufficient Funds";
const MINT_BUTTON_SOLD_OUT = "Sold Out";
const MINT_BUTTON_LIMIT_REACHED = "Limit Reached";
const MINT_BUTTON_INACTIVE = "Sale Paused";

const checkNetwork = (chainId) => {
  switch (chainId) {
    case 1:
      console.log("Connected to Mainnet");
      if (document.getElementById("mainnetBanner"))
        document.getElementById("mainnetBanner").classList.add("hidden");
      break;
    case 0x1:
    default:
      console.log("Connected to Testnet");
      if (document.getElementById("mainnetBanner"))
        document.getElementById("mainnetBanner").classList.remove("hidden");
      break;
  }
};

export default class extends Controller {
  static values = {
    template: String,
    contract: String,
    onPublicSale: Boolean,
    onPrivateSale: Boolean,
    externalContract: Boolean,
    disableTracking: Boolean,
    url: String,
  };

  static targets = [
    "connectButtonContainer",
    "mintButtonContainer",
    "errorContainer",
    "successContainer",
    "connectButton",
    "mintButton",
    "priceDisplay",
    "countDisplay",
    "maxPerWalletDisplay",
    "errorMessage",
    "successMessage",
    "onSaleBanner",
    "connectWalletButton",
    "walletIcon",
    "walletText",
    "walletDisconnect",
  ];

  async connect() {
    // let { ERC721ABI, ERC721ByteCode } = await import(`../contracts/${this.templateValue}/contract`);

    this.contractABI = JSON.parse(ERC721ABI);
    this.contractByteCode = ERC721ByteCode;

    this.mintStatus = {};

    this.hideSuccess();
    this.hideError();
  }

  async connectWallet() {
    if (this.connectingWallet) return;

    if (this.provider && this.destinationWallet) {
      await disconnectProvider(this.provider);

      // Clear internal variables
      this.provider = null;
      this.destinationWallet = null;

      // Update the UI to reflect disconnected state
      this.renderDisconnected();
    } else {
      this.renderConnecting();

      try {
        let provider = await connectWallet(
          checkNetwork,
          this.connectWallet.bind(this),
          this.onDisconnect.bind(this)
        );
        this.provider = provider;

        destinationWallet = await getAddress(this.provider);
        this.destinationWallet = destinationWallet;

        this.ensAddress = await getEnsAddress(this.provider);
      } catch (e) {
        // Something went wrong and we were unable to connect wallet. Switch
        // to disconnected state.
        Sentry.captureException(e);
        this.renderDisconnected();
      }

      let networkDetails = await this.provider.getNetwork();
      checkNetwork(networkDetails.chainId);

      this.contract = new ethers.Contract(
        this.contractValue,
        this.contractABI,
        this.provider
      );

      await this.populateDetails();

      this.renderConnected();
    }
  }

  async onDisconnect() {
    this.provider = null;
    this.destinationWallet = null;
    this.renderDisconnected();
  }

  connectMouseOver() {
    if (!this.provider) return false;

    if (this.hasWalletIconTarget) this.walletIconTarget.classList.add("hidden");
    if (this.hasWalletTextTarget) this.walletTextTarget.classList.add("hidden");
    if (this.hasWalletDisconnectTarget)
      this.walletDisconnectTarget.classList.remove("hidden");
  }

  connectMouseOut() {
    if (!this.provider) return false;

    if (this.hasWalletIconTarget)
      this.walletIconTarget.classList.remove("hidden");
    if (this.hasWalletTextTarget)
      this.walletTextTarget.classList.remove("hidden");
    if (this.hasWalletDisconnectTarget)
      this.walletDisconnectTarget.classList.add("hidden");
  }

  renderConnected() {
    this.connectingWallet = false;

    if (this.hasWalletTextTarget)
      this.walletTextTarget.innerText =
        this.ensAddress || formatAddress(this.destinationWallet);
    if (this.hasWalletIconTarget)
      this.walletIconTarget.classList.remove("hidden");

    this.hideConnectContainer();
    this.showMintContainer();
  }

  renderConnecting() {
    this.connectingWallet = true;
    this.setConnectButtonLabel(CONNECT_BUTTON_CONNECTING);
    if (this.hasWalletTextTarget)
      this.walletTextTarget.innerText = CONNECT_BUTTON_CONNECTING;
  }

  renderDisconnected() {
    this.connectingWallet = false;

    if (this.hasWalletTextTarget)
      this.walletTextTarget.innerText = CONNECT_BUTTON_CONNECT;
    if (this.hasWalletTextTarget)
      this.walletTextTarget.classList.remove("hidden");
    if (this.hasWalletIconTarget) this.walletIconTarget.classList.add("hidden");
    if (this.hasWalletDisconnectTarget)
      this.walletDisconnectTarget.classList.add("hidden");

    this.setConnectButtonLabel(CONNECT_BUTTON_CONNECT);
    this.hideMintContainer();
    this.showConnectContainer();
  }

  showMintContainer() {
    if (this.hasMintButtonContainerTarget)
      this.mintButtonContainerTarget.classList.remove("hidden");
  }

  hideMintContainer() {
    if (this.hasMintButtonContainerTarget)
      this.mintButtonContainerTarget.classList.add("hidden");
  }

  showConnectContainer() {
    if (this.hasConnectButtonContainerTarget)
      this.connectButtonContainerTarget.classList.remove("hidden");
  }

  hideConnectContainer() {
    if (this.hasConnectButtonContainerTarget)
      this.connectButtonContainerTarget.classList.add("hidden");
  }

  setMintButtonLabel(s) {
    if (this.hasMintButtonTarget) {
      this.mintButtonTarget.getElementsByTagName("span")[0].innerText = s;
    }
  }

  setConnectButtonLabel(s) {
    if (this.hasConnectButtonTarget) {
      this.connectButtonTarget.innerText = s;
    }
  }

  async getMintStatus() {
    let response = await verifyAddress(
      this.destinationWallet,
      this.contractValue
    );

    this.mintStatus = response;

    const { contractAddress } = this.mintStatus;

    if (contractAddress) {
      this.contract = new ethers.Contract(
        contractAddress,
        this.contractABI,
        this.provider
      );
      window.contract = this.contract;
      await this.populateDetails();
    }
  }

  async getPrice() {
    let response = this.contract.PRICE();
    return response;
  }

  async getMaxPerWallet() {
    let response;
    try {
      response = await this.contract.MAX_PER_WALLET();
    } catch (e) {
      return 100000;
    }
    return response;
  }

  async getTotalSupply() {
    let totalSupply = await this.contract.totalSupply();
    return parseInt(totalSupply);
  }

  async getTotalPublicSupply() {
    let totalSupply;
    try {
      totalSupply = await this.contract.totalPublicSupply();
    } catch (e) {
      return 0;
    }
    return parseInt(totalSupply);
  }

  async getMaxSupply() {
    let maxSupply;
    try {
      maxSupply = await this.contract.MAX_SUPPLY();
    } catch (e) {
      return 1000000;
    }
    return parseInt(maxSupply);
  }

  async getReservedSupply() {
    let reservedSupply;
    try {
      reservedSupply = await this.contract.MAX_RESERVED_SUPPLY();
    } catch (e) {
      return 0;
    }
    return parseInt(reservedSupply);
  }

  async getMaxMultiMint() {
    let response;
    try {
      response = this.contract.MAX_MULTIMINT();
    } catch (e) {
      return 0;
    }
    return response;
  }

  async getRemainingMints() {
    let response;
    try {
      response = this.contract.allowedMintCount(this.destinationWallet);
    } catch (e) {
      return 1;
    }
    return response;
  }

  async getSaleIsActive() {
    let response;
    try {
      response = this.contract.saleIsActive();
    } catch (e) {
      return true;
    }
    return response;
  }

  enableMintButton() {
    this.mintButtonTarget.disabled = false;
    this.mintButtonTarget.classList.add("button");
    this.mintButtonTarget.classList.remove("button-disabled");
  }

  disableMintButton() {
    this.mintButtonTarget.disabled = true;
    this.mintButtonTarget.classList.add("button-disabled");
    this.mintButtonTarget.classList.remove("button");
  }

  async populateDetails() {
    let totalSupply = await this.getTotalPublicSupply(); // Note, this is PUBLIC supply
    let maxSupply = await this.getMaxSupply();
    let reservedSupply = await this.getReservedSupply();
    let maxMint = await this.getMaxMultiMint();
    let maxPerWallet = await this.getMaxPerWallet();
    let remainingMints = await this.getRemainingMints();
    let saleIsActive = await this.getSaleIsActive();
    let remainingSupply = maxSupply - reservedSupply - totalSupply;

    this.price = await this.getPrice();

    if (this.hasPriceDisplayTarget) {
      this.priceDisplayTarget.innerText = `${ethers.utils.formatEther(
        `${this.price}`
      )} ETH`;
    }

    if (this.hasCountDisplayTarget) {
      this.countDisplayTarget.innerText = `${formatThousands(
        remainingSupply
      )} available`;
    }

    if (this.hasMaxPerWalletDisplayTarget) {
      this.maxPerWalletDisplayTarget.innerText = `Max ${maxPerWallet} per wallet`;
    }

    if (remainingSupply <= 0) {
      this.setMintButtonLabel(MINT_BUTTON_SOLD_OUT);
      this.hideMintContainer();
    } else {
      if (saleIsActive) {
        if (remainingMints <= 0) {
          this.setMintButtonLabel(MINT_BUTTON_LIMIT_REACHED);
        } else {
          this.enableMintButton();
        }
      } else {
        this.setMintButtonLabel(MINT_BUTTON_INACTIVE);
      }
    }

    // run this method in 30 seconds
    setTimeout(this.populateDetails.bind(this), 30000);
  }

  updatePrice() {
    let quantity = 1;
    let value = this.price * quantity;

    if (this.hasPriceDisplayTarget) {
      this.priceDisplayTarget.innerText = `${ethers.utils.formatEther(
        `${value}`
      )} ETH`;
    }
  }

  showError(s) {
    this.errorMessageTarget.innerText = s;
    this.errorContainerTarget.classList.remove("hidden");
  }

  hideError() {
    this.errorContainerTarget.classList.add("hidden");
  }

  showSuccess() {
    this.successContainerTarget.classList.remove("hidden");
  }

  hideSuccess() {
    this.successContainerTarget.classList.add("hidden");
  }

  hideSuccess() {
    this.successContainerTarget.classList.add("hidden");
  }

  async mint() {
    if (this.mintButtonTarget.classList.contains("button-disabled")) {
      return false;
    }

    this.hideError();
    this.hideSuccess();

    if (typeof this.contract === "undefined") {
      await this.connectWallet();
    }

    this.setLoadingState();
    this.setMintButtonLabel(MINT_BUTTON_MINTING);

    let quantity = 1;

    let value = this.price * quantity;

    const contract = new ethers.Contract(
      this.contractValue,
      this.contractABI,
      this.provider
    );

    let tx;
    try {
      tx = await contract
        .connect(this.provider.getSigner())
        .mint(quantity, { value: String(value) });

      let transactionHash = tx["hash"] || tx["transactionHash"];
      try {
        if (!this.disableTrackingValue) {
          let txResponse = logTransaction(
            transactionHash,
            this.destinationWallet,
            quantity
          );
        }
      } catch (e) {
        console.log(e);
      }

      if (!this.disableTrackingValue) {
        trackEvent("mint", this.destinationWallet, transactionHash, quantity);
      }

      this.setMintButtonLabel(MINT_BUTTON_WAITING);
      await tx.wait();

      this.populateDetails();
      this.setMintButtonLabel(MINT_BUTTON_MINTED);
      this.showSuccess();
      this.cancelLoadingState();
    } catch (e) {
      if (e["message"].indexOf("User denied transaction signature") >= 0) {
        this.setMintButtonLabel(MINT_BUTTON_DEFAULT);
        this.populateDetails();
        this.cancelLoadingState();
      } else if (e["message"].indexOf("insufficient funds") >= 0) {
        this.setMintButtonLabel(MINT_BUTTON_INSUFFICIENT_FUNDS);
        this.cancelLoadingState();
      } else {
        Sentry.captureException(e);
        console.log(e);
        this.setMintButtonLabel(MINT_BUTTON_DEFAULT);
        this.cancelLoadingState();
        this.showError("Transaction Failed - Please Try Again");
        return false;
      }
    }
  }

  setLoadingState() {
    this.disableMintButton();
  }

  cancelLoadingState() {
    this.enableMintButton();
  }
}

// format a number with commas
function formatThousands(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function formatAddress(address) {
  return (
    address.substring(0, 6) + "..." + address.substring(address.length - 4)
  );
}
