import { Controller } from "@hotwired/stimulus";
import * as Sentry from "@sentry/browser";
import { ethers } from "ethers";
import {
  connectWallet,
  getAddress,
  disconnectProvider,
  getNetwork,
  checkNetwork,
} from "../utils/connect_wallet";
import { getEnsAddress } from "../utils/web3utils";
import { formatAddress } from "../utils/formatters";
import { v4 as uuidv4 } from "uuid";
import { logRedemption } from "../requests/redeem_token";
import { checkRedeemables } from "../requests/check_redeemables";
import { scrollIntoView } from "../utils/dom_utils";

const UI_STRINGS = {
  CONNECT_BUTTON_CONNECT: "Connect Wallet",
  CONNECT_BUTTON_DISCONNECT: "Disconnect",
  CONNECT_BUTTON_CONNECTING: "Connecting...",
  REDEEM_BUTTON_REDEEMING: "Redeeming...",
  REDEEM_BUTTON_REDEEM: "Redeem Item",
  INDEX_BUTTON_REDEEM: "Redeem",
  ERROR_NOT_ELIGIBLE: "You do not have the required token to redeem this item.",
};

const ABI = [
  "function balanceOf(address account, uint256 id) view returns (uint256)",
  "function burn(address account, uint256 id, uint256 value)",
];

export default class extends Controller {
  static values = {
    contract: String,
  };

  static targets = [
    "introContainer",
    "connectContainer",
    "errorContainer",
    "historyContainer",
    "successContainer",
    "indexContainer",
    "itemList",
    "formContainer",
    "connectButton",
    "connectButtonText",
    "walletDisconnect",
    "redeemButton",
    "itemImage",
    "itemDescription",
    "itemConfirmation",
    "itemConfirmationImage",
  ];

  connect() {
    console.log("RedeemController connected");

    this.resetConnection();
    this.tokenBalance = 0;
    this.redeemables = [];
    this.currentItem = null;

    this.uuid = uuidv4();
  }

  isConnected() {
    return this.provider && this.destinationWallet;
  }

  resetConnection() {
    this.provider = null;
    this.destinationWallet = null;
    this.connectingWallet = false;
    this.redeeming = false;
    this.ensAddress = null;
    this.uuid = uuidv4();
    // this.renderDisconnected();
  }

  currentRedeemable() {
    return this.redeemables.find((redeemable) => {
      return redeemable.slug === this.currentItem;
    });
  }

  async connectWallet() {
    console.log("connectWallet");

    if (this.connectingWallet) return;

    if (this.isConnected()) {
      await disconnectProvider(this.provider);
      this.resetConnection();
    } else {
      try {
        this.connectingWallet = true;
        this.renderConnecting();
        this.provider = await connectWallet(
          checkNetwork,
          this.connectWallet.bind(this),
          this.resetConnection.bind(this)
        );
        this.destinationWallet = await getAddress(this.provider);
        this.ensAddress = await getEnsAddress(this.provider);
      } catch (e) {
        Sentry.captureException(e);
        this.resetConnection();
      }

      let networkDetails = await this.provider.getNetwork();
      checkNetwork(networkDetails.chainId);

      await this.addressCheck(networkDetails.chainId);

      this.renderConnected();

      if (this.redeemables.length > 0) {
        if (this.hasErrorContainerTarget)
          this.errorContainerTarget.classList.add("hidden");

        console.log("redeemables", this.redeemables);
        this.showIndex();
      } else {
        this.showErrorMessage(UI_STRINGS.ERROR_NOT_ELIGIBLE);
        this.introContainerTarget.classList.add("hidden");
      }
    }
  }

  async addressCheck(chainId) {
    const response = await checkRedeemables(this.destinationWallet, chainId);

    this.redeemables = response;
    this.updateIndex();
  }

  async redeem(event) {
    // TODO: Handle Payment if non-burnable.

    console.log("redeem");
    event.preventDefault();

    if (this.redeeming) return;

    this.redeeming = true;
    if (this.hasRedeemButtonTarget)
      this.redeemButtonTarget.innerText = UI_STRINGS.REDEEM_BUTTON_REDEEMING;

    // Read form fields and POST data to server.
    const form = event.target;
    const formData = new FormData(form);
    const data = {};
    formData.forEach((value, key) => {
      if (key === "country[]") key = "country";
      data[key] = value;
    });

    console.log(data);

    try {
      await logRedemption(
        this.uuid,
        this.destinationWallet,
        this.contractValue,
        null,
        data,
        this.currentRedeemable().id
      );
    } catch (e) {
      Sentry.captureException(e);
      this.showErrorMessage(e.message);
    }

    // Initiate Token Burn
    console.log(this);
    try {
      console.log("Burning Token", this.currentRedeemable().tokenId);

      this.contract = new ethers.Contract(
        this.currentRedeemable().contract,
        ABI,
        this.provider
      );

      const tx = await this.contract
        .connect(this.provider.getSigner())
        .functions.burn(
          this.destinationWallet,
          this.currentRedeemable().tokenId,
          1
        );

      let transactionHash = tx["hash"] || tx["transactionHash"];
      console.log(transactionHash);

      await logRedemption(
        this.uuid,
        this.destinationWallet,
        this.contractValue,
        transactionHash,
        data,
        this.currentRedeemable().id
      );

      const receipt = await tx.wait();
      console.log("receipt", receipt);

      await logRedemption(
        this.uuid,
        this.destinationWallet,
        this.contractValue,
        transactionHash,
        data,
        this.currentRedeemable().id
      );

      this.showSuccessMessage();
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
      this.showErrorMessage(e.message);
      this.redeeming = false;
      if (this.hasRedeemButtonTarget)
        this.redeemButtonTarget.innerText = UI_STRINGS.REDEEM_BUTTON_REDEEM;
    }

    return false;
  }

  selectItem(event) {
    console.log(event.params);
    this.currentItem = event.params.id;

    const redeemable = this.currentRedeemable();

    if (this.hasItemDescriptionTarget)
      this.itemDescriptionTarget.innerText = redeemable.strings.description;

    if (this.hasItemConfirmationTarget)
      this.itemConfirmationTarget.innerText = redeemable.strings.confirmation;

    if (this.hasItemImageTarget)
      this.itemImageTarget.src = redeemable.images.large;

    if (this.hasItemConfirmationImageTarget)
      this.itemConfirmationImageTarget.src = redeemable.images.large;

    if (this.hasIndexContainerTarget)
      this.indexContainerTarget.classList.add("hidden");

    if (this.hasFormContainerTarget)
      this.formContainerTarget.classList.remove("hidden");

    scrollIntoView("formContainer");
  }

  updateIndex() {
    if (this.hasItemListTarget) {
      this.itemListTarget.innerHTML = "";
      this.redeemables.forEach((redeemable) => {
        const item = document.createElement("li");
        item.classList.add("item-container");
        item.innerHTML = `<div class="item">
          <div class="item-title">${redeemable.strings.name}</div>
          <img class="item-image" src="${redeemable.images.thumb}">
          <button data-action="click->redeem#selectItem" data-redeem-id-param="${redeemable.slug}">${UI_STRINGS.INDEX_BUTTON_REDEEM}</button>
          </div>
        `;
        this.itemListTarget.appendChild(item);
      });
    }
  }

  goBack(event) {
    // TODO: Clear values of all form fields.

    this.showIndex();
  }

  showIndex() {
    if (this.hasIndexContainerTarget)
      this.indexContainerTarget.classList.remove("hidden");

    if (this.hasIntroContainerTarget)
      this.introContainerTarget.classList.add("hidden");

    if (this.hasFormContainerTarget)
      this.formContainerTarget.classList.add("hidden");

    scrollIntoView("indexContainer");
  }

  showSuccessMessage() {
    if (this.hasSuccessContainerTarget)
      this.successContainerTarget.classList.remove("hidden");
    if (this.hasFormContainerTarget)
      this.formContainerTarget.classList.add("hidden");
    if (this.hasErrorContainerTarget)
      this.errorContainerTarget.classList.add("hidden");

    scrollIntoView("successContainer");
  }

  showErrorMessage(text) {
    if (this.hasErrorTextTarget) this.errorTextTarget.innerText = text;
    if (this.hasErrorContainerTarget)
      this.errorContainerTarget.classList.remove("hidden");

    scrollIntoView("errorContainer");
  }

  renderConnected() {
    this.connectingWallet = false;

    if (this.hasConnectButtonTextTarget) {
      document.querySelectorAll("[data-redeem-target]").forEach((el) => {
        if (el.dataset.redeemTarget === "connectButtonText")
          el.innerText =
            this.ensAddress || formatAddress(this.destinationWallet);
      });
    }
  }

  renderConnecting() {
    if (this.hasConnectButtonTextTarget) {
      document.querySelectorAll("[data-redeem-target]").forEach((el) => {
        if (el.dataset.redeemTarget === "connectButtonText")
          el.innerText = UI_STRINGS.CONNECT_BUTTON_CONNECTING;
      });
    }
  }

  renderDisconnected() {
    if (this.hasConnectButtonTextTarget) {
      document.querySelectorAll("[data-redeem-target]").forEach((el) => {
        if (el.dataset.redeemTarget === "connectButtonText")
          el.innerText = UI_STRINGS.CONNECT_BUTTON_CONNECT;
      });
    }

    if (this.hasConnectButtonTextTarget)
      this.connectButtonTextTarget.classList.add("hidden");

    if (this.hasWalletDisconnectTarget)
      this.walletDisconnectTarget.classList.remove("hidden");

    if (this.hasErrorContainerTarget)
      this.errorContainerTarget.classList.add("hidden");

    if (this.hasSuccessContainerTarget)
      this.successContainerTarget.classList.add("hidden");

    if (this.hasHistoryContainerTarget)
      this.historyContainerTarget.classList.add("hidden");

    if (this.hasIntroContainerTarget)
      this.introContainerTarget.classList.remove("hidden");

    if (this.hasFormContainerTarget)
      this.formContainerTarget.classList.add("hidden");
  }

  connectMouseOver() {
    if (!this.provider) return false;

    if (this.hasConnectButtonTextTarget)
      this.connectButtonTextTarget.classList.add("hidden");
    if (this.hasWalletDisconnectTarget)
      this.walletDisconnectTarget.classList.remove("hidden");
  }

  connectMouseOut() {
    if (!this.provider) return false;

    if (this.hasConnectButtonTextTarget)
      this.connectButtonTextTarget.classList.remove("hidden");
    if (this.hasWalletDisconnectTarget)
      this.walletDisconnectTarget.classList.add("hidden");
  }
}
