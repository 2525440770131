import { Controller } from "@hotwired/stimulus";
import { connectWallet, requestAccounts } from "./index";
import { verifyAddress } from "../requests/verify_address";
import { checkNetwork } from "../utils/check_network";
import { checkMetaMask } from "../utils/check_metamask";

let destinationWallet;
let contractAddress;

export default class extends Controller {
  static values = {
    template: String,
    contract: String,
    onPublicSale: Boolean,
  };

  static targets = [
    "connectButton",
    "mintButton",
    "price",
    "quantitySlider",
    "totalValue",
    "quantityValue",
    "errorMessage",
    "errorContainer",
    "successContainer",
    "soldOutBanner",
  ];

  async connect() {
    console.log("connecting");
    let { ERC721ABI, ERC721ByteCode } = await import(
      `../contracts/${this.templateValue}/contract`
    );

    this.contractABI = JSON.parse(ERC721ABI);
    this.contractByteCode = ERC721ByteCode;

    this.mintStatus = {};
    this.price = 30000000000000000;

    this.updateQuantity();

    checkMetaMask();

    setTimeout(() => {
      checkNetwork();
    }, 1000);

    if (typeof ethereum !== "undefined") {
      window.ethereum.on("networkChanged", checkNetwork.bind(this));
      window.ethereum.on("accountsChanged", this.connectWallet.bind(this));
    }
  }

  updateQuantity() {
    this.quantityValueTarget.innerText = this.quantitySliderTarget.value;

    const totalPrice =
      Math.round(
        (this.quantitySliderTarget.value * this.price + Number.EPSILON) * 100
      ) / 100;
    this.totalValueTarget.innerText = Web3.utils.fromWei(`${totalPrice}`);
  }

  async connectWallet() {
    this.setButtonLabel("Connecting...");

    destinationWallet = await connectWallet();
    const accounts = await requestAccounts();

    this.destinationWallet = accounts[0];

    await this.getMintStatus();
  }

  setButtonLabel(s) {
    this.mintButtonTarget.getElementsByTagName("span")[0].innerText = s;
  }

  async getMintStatus() {
    console.log("getting mint status");
    let response = await verifyAddress(this.destinationWallet, null);

    this.mintStatus = response;
    console.log(this.mintStatus);
    if (!this.mintStatus.onPublicSale) {
      console.log("not on public sale");
      this.disableButton();
      this.setButtonLabel("Not on sale - come back later");
    } else {
      const { contractAddress } = this.mintStatus;

      if (contractAddress) {
        this.contract = new web3.eth.Contract(
          this.contractABI,
          contractAddress
        );
        window.contract = this.contract;
        await this.populateDetails();
      }
    }
  }

  async getPrice() {
    let response = this.contract.methods.PRICE().call();
    return response;
  }

  async getSaleStatus() {
    let response = this.contract.methods.saleIsActive().call();
    return response;
  }

  async getSupply() {
    let response = this.contract.methods.totalSupply().call();
    return response;
  }

  async getMaxSupply() {
    let response = this.contract.methods.MAX_SUPPLY().call();
    return response;
  }

  enableButton() {
    this.mintButtonTarget.disabled = false;
    this.mintButtonTarget.classList.remove("disabled");
  }

  disableButton() {
    this.mintButtonTarget.disabled = true;
    this.mintButtonTarget.classList.add("disabled");
  }

  async populateDetails() {
    console.log("populating details");
    this.saleActive = await this.getSaleStatus();
    this.supply = await this.getSupply();
    this.max_supply = await this.getMaxSupply();
    this.price = await this.getPrice();

    this.quantitySliderTarget.max = Math.min(
      this.max_supply - this.supply,
      100
    );
    this.quantitySliderTarget.value = Math.min(
      this.quantitySliderTarget.value,
      this.max_supply - this.supply
    );
    this.updateQuantity();

    this.priceTarget.innerText = `${Web3.utils.fromWei(this.price)}`;

    if (!this.saleActive) {
      this.disableButton();
      this.setButtonLabel("Sale is not active");
      // } else if (this.supply >= this.max_supply) {
      //   this.disableButton();
      //   this.setButtonLabel('Sold Out');
    } else {
      this.setButtonLabel("Mint");
    }
  }

  showError(s) {
    this.errorMessageTarget.innerText = s;
    this.errorContainerTarget.classList.remove("hidden");
  }

  hideError() {
    this.errorContainerTarget.classList.add("hidden");
  }

  showSuccess() {
    this.successContainerTarget.classList.remove("hidden");
  }

  hideSuccess() {
    this.successContainerTarget.classList.add("hidden");
  }

  async mint() {
    if (this.mintButtonTarget.classList.contains("disabled")) {
      return false;
    }

    this.hideError();
    this.hideSuccess();

    if (typeof this.contract === "undefined") {
      this.connectWallet();
    } else {
      this.setLoadingState();
      this.setButtonLabel("Minting...");

      let quantity = this.quantitySliderTarget.value;
      let value = this.price * quantity;

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(
        this.contractValue,
        this.contractABI,
        provider
      );

      try {
        let tx = await contract
          .connect(provider.getSigner())
          .mint(quantity, { value: String(value) });

        this.setButtonLabel("Waiting...");
        await tx.wait();

        this.populateDetails();
        this.setButtonLabel("Minted!");
        this.showSuccess();
        this.cancelLoadingState();
      } catch (e) {
        if (e["message"].indexOf("User denied transaction signature") >= 0) {
          this.populateDetails();
          this.cancelLoadingState();
        } else if (e["message"].indexOf("insufficient funds") >= 0) {
          this.setButtonLabel("Insufficient Funds");
          this.cancelLoadingState();
        } else if (e["message"].indexOf("Exceeds max supply") >= 0) {
          this.setButtonLabel("Mint");
          this.cancelLoadingState();
          this.showError("You cannot mint that many, try fewer");
        } else {
          this.setButtonLabel("Failed");
          this.cancelLoadingState();
          this.showError("Transaction Failed - Please Try Again");
        }
      }
    }
  }

  setLoadingState() {
    this.disableButton();
  }

  cancelLoadingState() {
    this.enableButton();
  }
}
