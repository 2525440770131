import "@hotwired/turbo-rails";
import "@rails/actioncable";
import "controllers";
import * as Sentry from "@sentry/browser";
import jQuery from "jquery";
import Rails from "@rails/ujs";

Sentry.init({
  dsn: "https://e7cae43300234c36b84c6bfdcf20c2cb@o1036852.ingest.sentry.io/6089350",
});

Rails.start();
