export const verifyAddress = (walletAddress, contractAddress) => {
  return fetch(`${window.masonConfig.urlBase}/site/verification`, {
    method: "POST",
    headers: {
      "X-CSRF-Token": document.head
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      wallet_address: walletAddress,
      contract_address: contractAddress,
    }),
  }).then((response) => response.json());
};
